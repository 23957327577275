import React, { useState, useEffect } from "react";
import "../login/login.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext"; // Dosya yolunu uygun şekilde değiştirin
import LoadingSpinner from "../LoadingSpinner";
import logo from "../../assets/images/propex_logo.png";
import { CiLock } from "react-icons/ci";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = localStorage.getItem("userData");

    if (token && userData) {
      navigate("/dashboards"); // dashboards sayfasına yönlendir
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      await login(values, rememberMe, setLoading);
    } catch {}
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="loginPage">
          <div className="formContainer mx-auto my-auto">
            <img className="w-72 login-logo" src={logo} alt="" />
            <Formik
              initialValues={{
                userName: "",
                password: "",
              }}
              validationSchema={Yup.object({
                userName: Yup.string().required("Kullanıcı adı gerekli !"),
                password: Yup.string().required("Şifre Gerekli !"),
              })}
              onSubmit={handleSubmit}
            >
              {({ values, errors, handleSubmit, handleChange }) => (
                <form className="flex flex-col gap-7" onSubmit={handleSubmit}>
                  <div className="inputGrp">
                    <label htmlFor="userName">Kullanıcı Adı :</label>
                    <input
                      onChange={handleChange}
                      value={values.userName}
                      id="userName"
                      placeholder="admin"
                      className="input-text"
                      type="text"
                    />
                  </div>
                  <div className="inputGrp">
                    <label htmlFor="password">Şifre : </label>
                    <input
                      onChange={handleChange}
                      value={values.password}
                      id="password"
                      placeholder="••••••••"
                      className="input-text"
                      type="password"
                    />
                  </div>

                  <div className="spanCont">
                    <div className="checkboxCont">
                      <label htmlFor="checkbox">Beni hatırla</label>
                      <input
                        className="input-checkbox"
                        id="checkbox"
                        type="checkbox"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                    </div>
                  </div>

                  <div>
                    <button type="submit" className="loginButton">
                      Giriş Yap
                    </button>
                    <Link to={"/registrationWizard"}>
                      <button type="button" className="form-button">
                        Yeni Müşteri
                      </button>
                    </Link>
                  </div>
                  <div className="text-end -mt-6">
                    <a className="flex items-center justify-end gap-2" href="#">
                      {" "}
                      <CiLock size={18} />
                      Şifremi unuttum
                    </a>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
