import React, { useEffect, useState } from "react";
import "../../demands/demands.css";
import { useNavigate } from "react-router-dom";
import api from "../../../api/api";
import Swal from "sweetalert2";
import { FaCheck } from "react-icons/fa";
import { useAuth } from "../../../context/AuthContext";
import Footer from "../../../components/bars/Footer";
import LoadingSpinner from "../../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import invoiceIcon from "../../../assets/images/e-invoice.svg";
import { GiCancel } from "react-icons/gi";

const PurchaseEinvoices = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState(null);
  const [customerID, setCustomerID] = useState(Number());
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını depolamak için
  const [pageSize, setPageSize] = useState(20);
  const [sortOrder, setSortOrder] = useState({
    invoiceNumber: "desc",
    totalPrice: "desc",
  });

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      // API çağrısı
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=42`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          // İlgili veriye sahipseniz diğer API çağrılarını yapın
          api
            .get(
              `/api/EInvoice/GetEInvoices?customerID=${user.country}&pageNumber=${currentPage}&pageSize=${pageSize}`
            )
            .then((res) => {
              setData(res.data.items);
              setTotalPages(res.data.totalPages);
              res.data.items.invoiceDate = formatDate(res.data.invoiceDate);
              res.data.items.expiryDate = formatDate(res.data.expiryDate);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, currentPage, pageSize]);

  ///////////////////////// PAGING
  const nextPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)); // Son sayfayı aşmamak için kontrol
  };

  const prevPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5 && currentPage > 3) {
      startPage = currentPage - 2;
      endPage = Math.min(currentPage + 2, totalPages);
    } else {
      endPage = Math.min(totalPages, 5);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`flex items-center justify-center px-4 h-10 leading-tight ${
              currentPage === i
                ? "text-blue-600 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }`}
            onClick={() => goToPage(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
  };

  const handleSort = (columnName) => {
    const sortOrderCopy = { ...sortOrder };
    sortOrderCopy[columnName] =
      sortOrder[columnName] === "asc" ? "desc" : "asc";
    setSortOrder(sortOrderCopy);
  
    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (columnName === "invoiceNumber") {
          // invoiceNumber string ise localeCompare kullan
          if (sortOrderCopy[columnName] === "asc") {
            return a[columnName]?.localeCompare(b[columnName]);
          } else {
            return b[columnName]?.localeCompare(a[columnName]);
          }
        } else if (columnName === "totalPrice") {
          // totalPrice sayısal olduğundan doğrudan karşılaştır
          if (sortOrderCopy[columnName] === "asc") {
            return a[columnName] - b[columnName];
          } else {
            return b[columnName] - a[columnName];
          }
        }
        // Varsayılan olarak diğer sütunları sayısal karşılaştırma ile sıralayın
        return numericSort(
          a[columnName],
          b[columnName],
          sortOrderCopy[columnName]
        );
      });
      return sortedData;
    });
  };
  

  const numericSort = (a, b, sortOrder) => {
    if (sortOrder === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };
  /////////////////////////////////////////////

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  // Kabul Et Fonksiyonu
  const handleAccept = async (referenceNumber) => {
    Swal.fire({
      title: "Faturayı kabul etmek istediğinize emin misiniz?",
      icon: "warning",
      confirmButtonColor: "#0C692F",
      cancelButtonColor: "#B10F0F",
      showCancelButton: true,
      confirmButtonText: "Evet, kabul et!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const token =
            localStorage.getItem("token") || sessionStorage.getItem("token");

          if (!token) {
            throw new Error("Token bulunamadı, lütfen giriş yapın.");
          }

          await api.put(
            `/api/EInvoice/Reply/Reply`,
            {
              customerID,
              items: [{
                referenceNumber: referenceNumber,
                replyState: 1,
                replyDescription: "", // Boş gönderilecek
              }],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "E-Fatura onaylandı!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          console.log(error);

          handleApiError(error);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  // Reddet Fonksiyonu
  const handleReject = async (referenceNumber) => {
    Swal.fire({
      title: "Reddetme sebebini girin",
      input: "textarea",
      inputPlaceholder: "Reddetme sebebinizi buraya yazın...",
      showCancelButton: true,
      confirmButtonText: "Gönder",
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#B10F0F",
    }).then(async (inputResult) => {
      if (inputResult.isConfirmed) {
        const replyDescription = inputResult.value || "Sebep belirtilmedi";
        setLoading(true);
        try {
          const token =
            localStorage.getItem("token") || sessionStorage.getItem("token");

          if (!token) {
            throw new Error("Token bulunamadı, lütfen giriş yapın.");
          }

          await api.put(
            `/api/EInvoice/Reply/Reply`,
            {
              customerID,
              items: [{
                referenceNumber: referenceNumber,
                replyState: 2,
                replyDescription: replyDescription,
              }],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "E-Fatura reddedildi!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          handleApiError(error);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleApiError = (error) => {
    const errorMessageKey = error.response?.data;
    const translatedError = errorMessageKey
      ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
      : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
    Swal.fire({
      position: "top-end",
      icon: "error",
      title: `${translatedError}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleDownloadEInvoice = async (id) => {
    setLoading(true); // Yükleme durumunu başlat
    try {
      const response = await api.get(`/api/EInvoice/DownloadEInvoice?id=${id}`);

      // Base64 PDF verisi
      const base64PDF = response.data; // API'den dönen veri burada olmalı
      const binary = atob(base64PDF); // Base64 çözümü
      const array = Uint8Array.from(binary, (char) => char.charCodeAt(0));

      // Blob oluştur
      const blob = new Blob([array], { type: "application/pdf" });

      // URL oluştur
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `E-Fatura-${id}.pdf`; // İndirilecek dosyanın adı
      document.body.appendChild(link);
      link.click();

      // Temizleme
      link.remove();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("E-Fatura indirme sırasında hata oluştu:", error);

      // Hata durumu
      Swal.fire({
        icon: "error",
        title: "Hata!",
        text: "E-Fatura indirilemedi, lütfen tekrar deneyin.",
      });
    } finally {
      setLoading(false); // Yükleme durumunu bitir
    }
  };

  console.log(data);
  
  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <>
          <div className="add-button pt-6">
            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title ">
                Gelen E-Faturalar
              </h1>
            </div>
          </div>
          <div className="relative overflow-x-auto pt-6">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                <tr>
                  <th scope="col" className="px-6 py-3"></th>
                  <th
                    scope="col"
                    className="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort("invoiceNumber")}
                  >
                    Fatura Numarası {sortOrder.invoiceNumber === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3"
                  >
                    Fatura Tarihi 
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3"
                  >
                    Cari 
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort("totalPrice")}
                  >
                    Fiyat {sortOrder.totalPrice === "asc" ? "▲" : "▼"}
                  </th>

                  <th scope="col" className="px-6 py-3">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data
                    ?.filter(
                      (item) =>
                        item.invoiceNumber
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.invoiceDate
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                          item?.account?.name
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.totalPrice
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                    )
                    .map((item, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b hover:bg-gray-50"
                      >
                        <td className="px-3 py-4">
                          <button
                            title="E-Fatura indir"
                            onClick={() =>
                              handleDownloadEInvoice(item.eInvoiceID)
                            }
                          >
                            <img
                              width={38}
                              style={{ maxWidth: "25px" }}
                              src={invoiceIcon}
                              alt="E-Fatura Icon"
                            />
                          </button>
                        </td>
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {item?.invoiceNumber}
                        </th>
                        <td className="px-6 py-4">
                          {formatDate(item.invoiceDate)}
                        </td>
                        <td className="px-6 py-4">{item?.account?.name}</td>
                        <td className="px-6 py-4">{item?.totalPrice}</td>

                        <td className="flex items-center px-6 py-4 space-x-3">
                          <button
                            onClick={() => handleAccept(item.referenceNumber)}
                            className="font-medium text-[#050A27] hover:underline"
                            title="Kabul et"
                          >
                            <FaCheck
                              className="hover:scale-110 transition-transform duration-200"
                              color="#0C692F"
                              size={21}
                            />
                          </button>
                          <button
                            onClick={() => handleReject(item.referenceNumber)}
                            className="font-medium text-[#050A27] hover:underline"
                            title="Reddet"
                          >
                            <GiCancel
                              className="hover:scale-110 transition-transform duration-200"
                              color="#B10F0F"
                              size={21}
                            />
                          </button>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="px-6 py-4 text-center text-2xl font-medium"
                    >
                      Henüz kayıtlı bir veri yoktur.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <nav
            className="mt-2 float-right my-5"
            aria-label="Page navigation example"
          >
            <ul className="flex items-center -space-x-px h-10 text-sm">
              <li>
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className={`flex items-center justify-center px-4 h-10 ${
                    currentPage === 1
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 1 1 5l4 4"
                    />
                  </svg>
                </button>
              </li>
              {renderPageNumbers()}
              <li>
                <button
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                  className={`flex items-center justify-center pl-4 h-10 ${
                    currentPage === totalPages
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}

      <Footer />
    </>
  );
};

export default PurchaseEinvoices;
