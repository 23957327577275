import Sidebars from "../../components/bars/Sidebar";
import Header from "../../components/bars/Header";
import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import "leaflet/dist/leaflet.css";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import Switch from "react-switch";

const PotentialAccountEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(Number());
  const [data, setData] = useState({});

  //FORM STATE
  const [name, setName] = useState("");
  const [contactName, setContactName] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [postCode, setPostCode] = useState("");

  const [description, setDescription] = useState("");
  const [customerKey, setCustomerKey] = useState([]);

  //FOR SELECT BOX OPTIONS
  const [getCity, setGetCity] = useState([]);
  const [getDistrict, setGetDistrict] = useState([]);
  const [accountSource, setAccountSource] = useState([]);
  const [accountTrackingStatus, setAccountTrackingStatus] = useState([]);

  //SELECT VALUE STATES
  const [city, setCity] = useState({});
  const [district, setDistrict] = useState({});
  const [keywords, setKeywords] = useState([]);

  //CRM
  const [crM_LeadSource, setCrM_LeadSource] = useState({});
  const [crM_FirstTouchDate, setCrM_FirstTouchDate] = useState(null);
  const [crM_ProductInterestPoint, setCrM_ProductInterestPoint] = useState(0);
  const [crM_EstimatedBudget, setCrM_EstimatedBudget] = useState(Number());
  const [crM_PotentialSalesValue, setCrM_PotentialSalesValue] = useState(
    Number()
  );
  const [crM_TrackingStatus, setCrM_TrackingStatus] = useState({});
  const [crM_InterestedProductTypes, setCrM_InterestedProductTypes] =
    useState("");
  const [crM_ProblemAndNeeds, setCrM_ProblemAndNeeds] = useState("");
  const [crM_IsItUrgent, setCrM_IsItUrgent] = useState(false);
  const [crM_DecisionAuthority, setCrM_DecisionAuthority] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=1044`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get("/api/Common/GetParameters?parametername=City")
            .then((res) => {
              setGetCity(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=District")
            .then((res) => {
              setGetDistrict(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=AccountSource")
            .then((res) => {
              setAccountSource(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });
          api
            .get(
              "/api/Common/GetParameters?parametername=AccountTrackingStatus"
            )
            .then((res) => {
              setAccountTrackingStatus(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Account/GetPotentialAccount?id=${params.id}&customerID=${user.country}`
            )
            .then((res) => {
              setCrM_LeadSource({
                value: res.data.crM_LeadSource,
                label: res.data.crM_LeadSourceString,
              });
              setCrM_FirstTouchDate(
                res.data.crM_FirstTouchDate
                  ? new Date(res.data.crM_FirstTouchDate)
                  : null
              );
              setCrM_ProductInterestPoint(res.data.crM_ProductInterestPoint);
              setCrM_InterestedProductTypes(
                res.data.crM_InterestedProductTypes
              );
              setCrM_ProblemAndNeeds(res.data.crM_ProblemAndNeeds);
              setCrM_IsItUrgent(res.data.crM_IsItUrgent);
              setCrM_EstimatedBudget(res.data.crM_EstimatedBudget);
              setCrM_DecisionAuthority(res.data.crM_DecisionAuthority);
              setCrM_PotentialSalesValue(res.data.crM_PotentialSalesValue);
              setCrM_TrackingStatus({
                value: res.data.crM_TrackingStatus,
                label: res.data.crM_TrackingStatusString,
              });
              setData(res.data);
              setCustomerID(res.data.customerID);
              setName(res.data.name);
              setContactName(res.data.contactName);
              setCellphone(res.data.cellphone);
              setAddress(res.data.address);
              setEmail(res.data.email);
              setWebUrl(res.data.webUrl);
              setPostCode(res.data.postCode);
              setDescription(res.data.description);
              setCity({ label: res.data.cityName, value: res.data.cityID });
              setDistrict({
                label: res.data.districtName,
                value: res.data.districtID,
              });
              const data = JSON.parse(res.data.keywords);
              const newData = data.map((item) => ({
                value: item,
                label: item,
                __isNew__: true,
              }));
              setKeywords(newData);
           
            })
            .catch((err) => {
              console.log(err.data);
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  //// SELECT OPTIONS /////////

  const cityOptions = getCity?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const districtOptions = getDistrict?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const accountSourceOpt = accountSource?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));
  const accountTrackingStatusOpt = accountTrackingStatus?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  // TO STRING FUNCTION
  const stringKeywords = keywords?.map((item) => item.label);

  const postData = {
    accountID: params.id,
    customerID,
    name,
    contactName,
    cellphone,
    address,
    email,
    webUrl,
    postCode,
    cityID: city.value,
    districtID: district.value,
    description,
    keywords: JSON.stringify(stringKeywords),
    Notes: "",
    crM_LeadSource: crM_LeadSource?.value,
    crM_FirstTouchDate,
    crM_ProductInterestPoint,
    crM_EstimatedBudget,
    crM_PotentialSalesValue,
    crM_TrackingStatus: crM_TrackingStatus?.value,
    crM_InterestedProductTypes,
    crM_ProblemAndNeeds,
    crM_IsItUrgent,
    crM_DecisionAuthority,
  };

  const handleRating = (rating) => {
    setCrM_ProductInterestPoint(rating);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Giriş yapmanız gerekiyor.",
      });
      setLoading(false);
      return;
    }

    api
      .put("/api/Account/PutPotentialAccount/PutPotentialAccount", postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Potansiyel müşteri güncellendi!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/potentialAccounts");
      })
      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire(translatedError);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Müşteriler", href: "/accounts" },
                { label: "Müşteri Düzenle", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
              <div className="mb-12 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">
                  Potansiyel Müşteri Düzenle
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-3 gap-7 items-center w-full"
              >
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Cari Adı :
                  </label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="contactName"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kişi adı :
                  </label>
                  <input
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                    type="text"
                    id="contactName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="address"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Adres :
                  </label>
                  <input
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    type="text"
                    id="address"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Mail :
                  </label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="webUrl"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Web Site :
                  </label>
                  <input
                    value={webUrl}
                    onChange={(e) => setWebUrl(e.target.value)}
                    type="text"
                    id="webUrl"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="postCode"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Posta Kodu :
                  </label>
                  <input
                    value={postCode}
                    onChange={(e) => setPostCode(e.target.value)}
                    type="text"
                    id="postCode"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="city"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    İl :
                  </label>
                  <Select
                    value={city}
                    onChange={(e) => setCity(e)}
                    options={cityOptions}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    İlçe :
                  </label>
                  <Select
                    value={district}
                    onChange={(e) => setDistrict(e)}
                    options={districtOptions}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>

                <div className="flex flex-col gap-[20px]">
                  <div>
                    <label
                      htmlFor="cellphone"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Cep No :
                    </label>
                    <input
                      value={cellphone}
                      onChange={(e) => setCellphone(e.target.value)}
                      type="number"
                      id="cellphone"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                </div>

                <div className="col-span-3">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Anahtar Kelimeler :
                  </label>
                  <CreatableSelect
                    isMulti
                    options={customerKey}
                    onChange={(e) => setKeywords(e)}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>
                <div className="col-span-3">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    type="text"
                    id="description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <h1 className="text-xl font-semibold pt-5 text-[#050A27]">
                  CRM
                </h1>
                <div className="col-span-3 grid grid-cols-2 gap-6 items-center w-full border-2 border-gray-300 p-6">
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Müşteri Neden Geldi :
                    </label>
                    <Select
                      classNamePrefix="custom-select"
                      maxMenuHeight={200}
                      placeholder="Seçiniz..."
                      options={accountSourceOpt}
                      value={crM_LeadSource}
                      onChange={(e) => setCrM_LeadSource(e)}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      İlk Temas Tarihi :
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      showTimeSelect
                      locale={tr}
                      dateFormat="dd/MM/yyyy - HH:mm"
                      selected={crM_FirstTouchDate}
                      onChange={(date) => setCrM_FirstTouchDate(date)}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Ürüne İlgi :
                    </label>
                    <div className="flex items-center">
                      {[2, 4, 6, 8, 10].map((value) => (
                        <svg
                          key={value}
                          className={`w-8 h-8 ms-3 ${
                            value <= crM_ProductInterestPoint
                              ? "text-yellow-300"
                              : "text-gray-300 dark:text-gray-500"
                          }`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 22 20"
                          onClick={() => handleRating(value)}
                          style={{ cursor: "pointer" }}
                        >
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                        </svg>
                      ))}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="crM_InterestedProductTypes"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Talep Edilen Ürün / Hizmet:
                    </label>
                    <input
                      type="text"
                      id="crM_InterestedProductTypes"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={crM_InterestedProductTypes}
                      onChange={(e) =>
                        setCrM_InterestedProductTypes(e.target.value)
                      }
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="crM_ProblemAndNeeds"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Problem ve İstekler :
                    </label>
                    <input
                      id="crM_ProblemAndNeeds"
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={crM_ProblemAndNeeds}
                      onChange={(e) => setCrM_ProblemAndNeeds(e.target.value)}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="crM_EstimatedBudget"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Tahmini Bütçesi :
                    </label>
                    <input
                      id="crM_PotentialSalesValue"
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={crM_EstimatedBudget}
                      onChange={(e) => setCrM_EstimatedBudget(e.target.value)}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="crM_PotentialSalesValue"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Potansiyel Satış Değeri :
                    </label>
                    <input
                      type="number"
                      id="crM_PotentialSalesValue"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={crM_PotentialSalesValue}
                      onChange={(e) =>
                        setCrM_PotentialSalesValue(e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Takip Durumu :
                    </label>
                    <Select
                      classNamePrefix="custom-select"
                      maxMenuHeight={200}
                      placeholder="Seçiniz..."
                      options={accountTrackingStatusOpt}
                      value={crM_TrackingStatus}
                      onChange={(e) => setCrM_TrackingStatus(e)}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Karar Yetkisi Var mı :
                    </label>
                    <Switch
                      checked={crM_DecisionAuthority}
                      onChange={(e) => setCrM_DecisionAuthority(e)}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Talep Acil mi :
                    </label>
                    <Switch
                      checked={crM_IsItUrgent}
                      onChange={(e) => setCrM_IsItUrgent(e)}
                    />
                  </div>
                </div>
                {permissions.updatePermission && (
                  <div className="col-span-3 flex justify-end gap-[20px]">
                    <button
                      type="submit"
                      className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                      title="Kaydet"
                    >
                      Kaydet
                      <i className="fa-regular fa-floppy-disk ml-2"></i>
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PotentialAccountEdit;
