import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

// Font ayarları
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

// Stil ayarları
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 15,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "black",
    fontFamily: "Roboto",
  },
  table: {
    display: "table",
    width: "auto",
    margin: 12,
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "20%", // Adjust the width as needed
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Roboto",
    color: "black",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: "Roboto",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "Roboto",
  },
  logoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 170, // Adjust width and height as needed
    height: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 140, // Adjust width and height to fit your logo
    height: 40,
  },
  footer: {
    fontSize: 12,
    marginTop: 20,
    textAlign: "right",
    color: "black",
    fontFamily: "Roboto",
  },
});

const AccountMovementsPDF = ({ logoStr, pdfData, data }) => {
  return (
    <Document>
      <Page style={styles.body}>
        {/* Dinamik logo URL'si için Image bileşeni */}
        <View style={styles.logoContainer}>
          {logoStr && <Image src={logoStr} style={styles.logo} />}
        </View>

        <Text style={styles.header} fixed>
          Cari Hareket Ekstresi
        </Text>
        <Text style={styles.header} fixed>
          Belge No: {pdfData}
        </Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>ID</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Borç</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Alacak</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}> Kümüle Toplam</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Açıklama</Text>
            </View>
          </View>

          {data?.accountCredits?.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.creditID}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.borc}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.alacak}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.kumuluToplam}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.description}</Text>
              </View>
            </View>
          ))}
        </View>

        {/* Toplam Borç veya Alacak */}
        <Text style={styles.footer}>
          {data?.total < 0
            ? `Borç: ${Math.abs(data.total)}`
            : `Alacak: ${data.total}`}
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default AccountMovementsPDF;
