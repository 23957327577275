import React, { useEffect, useState } from "react";
import "./register.css";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import logo from "../../assets/images/propex_logo.png";
import Swal from "sweetalert2";
import api from "../../api/api";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

const Register = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [modules, setModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [getCity, setGetCity] = useState([]);
  const [getDistrict, setGetDistrict] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    label: "+03:00",
    value: "+03:00",
  });
  const [city, setCity] = useState({
    label: "Seçiniz...",
    value: 0,
  });
  const [district, setDistrict] = useState({
    label: "Seçiniz...",
    value: 0,
  });
  const [formData, setFormData] = useState({
    inviteCode: "",
    customerName: "",
    customerLongName: "",
    email: "",
    companyPhone: "",
    taxOffice: "",
    taxNumber: "",
    mersisNumber: "",
    address: "",
    userName: "",
    name: "",
    surname: "",
    userMail: "",
    gsm: "",
    contactNameSurname: "",
  });

  useEffect(() => {
    api
      .get("/api/Customer/GetModules")
      .then((res) => {
        setModules(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
    api
      .get("/api/Common/GetParameters?parametername=City")
      .then((res) => {
        setGetCity(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get(`/api/Customer/GetTimeZones`)
      .then((res) => {
        const newData = res.data?.map((item) => ({
          value: item,
          label: item,
        }));
        setTimeZones(newData);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, []);

  const districtChange = (e) => {
    setCity(e);
    api
      .get(`/api/Common/GetDistricts?city=${e.parameterValue1}`)
      .then((res) => {
        setGetDistrict(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      })
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      if (name === "email") {
        return {
          ...prevData,
          email: value,
          userName: value,
        };
      }

      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const cityOptions = getCity?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
    parameterValue1: item.parameterValue1,
  }));

  const districtOptions = getDistrict?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.inviteCode) {
      Swal.fire({
        title: "Hata!",
        text: "Lütfen davet kodunu giriniz.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
      return;
    }

    setLoading(true);
    try {
      const response = await api.get(
        `/api/Customer/CheckInviteCode?code=${formData.inviteCode}`
      );

      if (response.data) {
        setCurrentStep(2);
      } else {
        Swal.fire({
          title: "Hata!",
          text: "Geçersiz davet kodu.",
          icon: "error",
          confirmButtonText: "Tekrar dene",
        });
      }
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleCheckboxChange = (module, isChecked) => {
    if (isChecked) {
      setSelectedModules((prev) => [
        ...prev,
        { moduleID: module.moduleID, name: module.name, parentModules: [] },
      ]);
    } else {
      setSelectedModules((prev) =>
        prev.filter((item) => item.moduleID !== module.moduleID)
      );
    }
  };

  const renderModules = (modules) => {
    return modules.map((module) => (
      <div key={module.moduleID} className="ml-4">
        <label>
          <input
            type="checkbox"
            checked={selectedModules.some(
              (item) => item.moduleID === module.moduleID
            )}
            onChange={(e) => handleCheckboxChange(module, e.target.checked)}
          />
          {module.name}
        </label>
        {module.parentModules.length > 0 && (
          <div className="ml-4">{renderModules(module.parentModules)}</div>
        )}
      </div>
    ));
  };

  const postData = {
    inviteCode: formData.inviteCode,
    email: formData.email,
    customerName: formData.customerName,
    customerLongName: formData.customerLongName,
    timeZone: selectedTimeZone?.value,
    contactNameSurname: formData.contactNameSurname,
    companyPhone: formData.companyPhone,
    gsm: formData.gsm,
    cityID: city?.value,
    districtID: district?.value,
    address: formData.address,
    customerLogo: "",
    taxOffice: formData.taxOffice,
    taxNumber: formData.taxNumber,
    mersisNumber: formData.mersisNumber,
    modules: selectedModules,
    userName: formData.userName,
    name: formData.name,
    surname: formData.surname,
    userMail: formData.userMail,
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const fieldsToCheck = [
      { field: "inviteCode", label: "Davet Kodu" },
      { field: "email", label: "E-mail" },
      { field: "customerName", label: "Firma Kısa Adı" },
      { field: "customerLongName", label: "Firma Uzun Adı" },
      { field: "contactNameSurname", label: "İletişim Adı ve Soyadı" },
      { field: "companyPhone", label: "Firma Tel No" },
      { field: "address", label: "Adres" },
      { field: "taxOffice", label: "Vergi Dairesi" },
      { field: "taxNumber", label: "Vergi No" },
      { field: "userName", label: "Kullanıcı Adı" },
      { field: "name", label: "Ad" },
      { field: "surname", label: "Soyad" },
      { field: "gsm", label: "GSM" },
      { field: "userMail", label: "Kullanıcı E-mail" },
    ];

    for (let { field, label } of fieldsToCheck) {
      if (!formData[field] && field !== "modules" && field !== "customerLogo") {
        Swal.fire({
          title: "Eksik Bilgi!",
          text: `${label} alanını doldurmanız gerekiyor.`,
          icon: "error",
          confirmButtonText: "Tamam",
        });
        return;
      }
    }

    if (selectedTimeZone?.value === 0) {
      Swal.fire({
        title: "Eksik Bilgi!",
        text: "Zaman Dilimi seçmeniz gerekiyor.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
      return;
    }

    if (city?.value === 0) {
      Swal.fire({
        title: "Eksik Bilgi!",
        text: "İl seçmeniz gerekiyor.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
      return;
    }

    if (district?.value === 0) {
      Swal.fire({
        title: "Eksik Bilgi!",
        text: "İlçe seçmeniz gerekiyor.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
      return;
    }

    if (selectedModules.length <= 0) {
      Swal.fire({
        title: "Eksik Bilgi!",
        text: "Modül seçmeniz gerekiyor.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await api.post(
        "/api/Customer/PostCustomerRegister/PostCustomerRegister",
        postData
      );

      setCurrentStep(5);
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);

      const errorMessageKey = error?.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: translatedError,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="registerPage">
          <div className="registerFormContainer mx-auto py-auto overflow-y-auto">
            <img
              onClick={() => navigate("/")}
              className="w-72 login-logo cursor-pointer"
              src={logo}
              alt="Propex Logo"
            />

            {/* Adım 1 - Davet Kodu Girişi */}
            {currentStep === 1 && (
              <div className="flex flex-col gap-7 w-[80%]">
                <h2 className="text-center text-white">
                  Kayıt Sihirbazı - Adım 1 / 4
                </h2>
                <div className="inputGrp">
                  <label htmlFor="inviteCode">Davet Kodu:</label>
                  <input
                    id="inviteCode"
                    name="inviteCode"
                    placeholder="Davet kodunu girin"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    value={formData.inviteCode}
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <button
                    type="button"
                    className="loginButton"
                    onClick={handleSubmit}
                  >
                    Gönder
                  </button>
                </div>
              </div>
            )}

            {/* Adım 2 - Firma Bilgileri */}
            {currentStep === 2 && (
              <div className="flex flex-col px-10 w-full gap-3 mb-6 md:grid-cols-2 md:grid ">
                <h2 className="col-span-2 text-white text-center">
                  Kayıt Sihirbazı - Adım 2 / 4
                </h2>
                <h3 className="title-input col-span-2 my-4">Firma Bilgileri</h3>
                <div className="inputGrp col-span-2">
                  <label htmlFor="contactNameSurname">
                    İletişim Adı ve Soyadı:
                  </label>
                  <input
                    id="contactNameSurname"
                    name="contactNameSurname"
                    placeholder="İletişim adı ve soyadı giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    value={formData.contactNameSurname}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="customerName">Firma Kısa Adı:</label>
                  <input
                    id="customerName"
                    name="customerName"
                    placeholder="Firma kısa adını giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    value={formData.customerName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="customerLongName">Firma Uzun Adı:</label>
                  <input
                    id="customerLongName"
                    name="customerLongName"
                    placeholder="Firma uzun adını giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    value={formData.customerLongName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="email">E-mail:</label>
                  <input
                    id="email"
                    name="email"
                    placeholder="E-mail adresinizi giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="companyPhone">Firma Tel No:</label>
                  <InputMask
                    id="companyPhone"
                    name="companyPhone"
                    mask="(999) 999-9999" // Burada telefon numarasının maskesini tanımlıyoruz
                    placeholder="Firma telefon numarasını giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={formData.companyPhone}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="mersisNumber">Mersis No:</label>
                  <InputMask
                    id="mersisNumber"
                    name="mersisNumber"
                    mask="9999999999999999"
                    placeholder="Mersis numarasını giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={formData.mersisNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="taxOffice">Vergi Dairesi:</label>
                  <input
                    id="taxOffice"
                    name="taxOffice"
                    placeholder="Vergi dairesini giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    value={formData.taxOffice}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="taxNumber">Vergi No:</label>
                  <input
                    id="taxNumber"
                    name="taxNumber"
                    placeholder="Vergi numarasını giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="number"
                    value={formData.taxNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="timeZone">Zaman Dilimi:</label>
                  <Select
                    value={selectedTimeZone}
                    onChange={(e) => setSelectedTimeZone(e)}
                    maxMenuHeight={200}
                    options={timeZones}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="city">İl:</label>
                  <Select
                    value={city}
                    onChange={(e) => districtChange(e)}
                    options={cityOptions}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="district">İlçe:</label>
                  <Select
                    value={district}
                    onChange={(e) => setDistrict(e)}
                    options={districtOptions}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="inputGrp col-span-2">
                  <label htmlFor="address">Adres:</label>
                  <textarea
                    id="address"
                    type="text"
                    name="address"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    rows={4}
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-span-2">
                  <button
                    type="button"
                    className="loginButton"
                    onClick={handleNext}
                  >
                    İleri
                  </button>
                </div>
              </div>
            )}

            {/* Adım 3 - Sonraki Adım */}
            {currentStep === 3 && (
              <div className="flex flex-col gap-4 w-[80%]">
                <h2 className="text-center text-white">
                  Kayıt Sihirbazı - Adım 3 / 4
                </h2>
                <h3 className="title-input">Kullanıcı Bilgileri</h3>
                <div className="inputGrp">
                  <label htmlFor="userName">Kullanıcı Adı:</label>
                  <input
                    id="userName"
                    name="userName"
                    placeholder="Kullanıcı adı giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    value={formData.userName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="name">Adı:</label>
                  <input
                    id="name"
                    name="name"
                    placeholder="Ad giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="surname">Soyadı:</label>
                  <input
                    id="surname"
                    name="surname"
                    placeholder="Soyad giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    value={formData.surname}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="gsm">GSM:</label>
                  <InputMask
                    id="gsm"
                    name="gsm"
                    mask="(999) 999-9999" 
                    placeholder="GSM giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={formData.gsm}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="userMail">E-mail:</label>
                  <input
                    id="userMail"
                    name="userMail"
                    placeholder="E-mail giriniz"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="email"
                    value={formData.userMail}
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <button
                    type="button"
                    className="previousButton"
                    onClick={handlePrevious}
                  >
                    Geri
                  </button>
                  <button
                    type="button"
                    className="loginButton"
                    onClick={handleNext}
                  >
                    İleri
                  </button>
                </div>
              </div>
            )}

            {currentStep === 4 && (
              <div className="flex flex-col gap-7 w-[80%]">
                <h2 className="text-center text-white">
                  Kayıt Sihirbazı - Adım 4 / 4
                </h2>
                <h3 className="title-input">Modül Seçimi</h3>
                <div>{renderModules(modules)}</div>
                <div>
                  <button
                    type="button"
                    className="previousButton"
                    onClick={handlePrevious}
                  >
                    Geri
                  </button>
                  <button
                    type="button"
                    className="loginButton"
                    onClick={handleRegister}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            )}
            {currentStep === 5 && (
              <div className="flex flex-col gap-7 w-[80%] pt-16">
                <h2 className="text-white text-3xl font-light">
                  Sayın{" "}
                  <span className="text-yellow-500 font-normal">
                    {formData.name ? formData.name : ".............."}{" "}
                    {formData.surname}
                  </span>
                  , <br />
                  Propex'e hoşgeldiniz.
                </h2>
                <h3 className="text-white text-2xl font-light">
                  Kullanıcı bilgileriniz{" "}
                  <span className="text-yellow-500">
                    SMS ile gönderilmiştir
                  </span>
                  . <br />
                  Kullanıcı e-posta adresinize gelen maili onayladıktan sonra bu
                  bilgiler ile sisteme giriş yapabilirsiniz.
                </h3>
                <Link to={"/"}>
                  <button type="button" className="loginButton">
                    Giriş Yap
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
