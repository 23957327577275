import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa6";
import * as XLSX from "xlsx";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import AccountMovementPdfModal from "../accounts/AccountMovementPdfModal";

const FinancialRegisterMovements = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setLoading(true);

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=2045`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(
              `/api/Register/GetAccountCreditByRegister?customerID=${user.country}&id=${params.id}`
            )
            .then((res) => {
              setData(res.data);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  ///////////////////////// PAGING

  // ITEMS TO EXCEL
  const handleExportData = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token");
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPdfData(response.data);

      const worksheet = XLSX.utils.json_to_sheet(data?.accountCredits);

      // Örneğin, A, B, C sütunları için genişlik ayarı
      worksheet["!cols"] = [
        { wch: 15 }, // A sütunu genişliği
        { wch: 15 }, // B sütunu genişliği
        { wch: 60 }, // C sütunu genişliği
        { wch: 10 }, // D sütunu genişliği
        { wch: 50 }, // E sütunu genişliği
        { wch: 50 }, // F sütunu genişliği
        { wch: 20 }, // G sütunu genişliği
        { wch: 60 }, // H sütunu genişliği
        { wch: 20 }, // I sütunu genişliği
        { wch: 20 }, // J sütunu genişliği
        { wch: 60 }, // K sütunu genişliği
        { wch: 40 }, // L sütunu genişliği
        { wch: 40 }, // M sütunu genişliği
        { wch: 10 }, // N sütunu genişliği
        { wch: 10 }, // O sütunu genişliği
        { wch: 10 }, // P sütunu genişliği
        { wch: 7 }, // Q sütunu genişliği
        { wch: 10 }, // R sütunu genişliği
        { wch: 10 }, // S sütunu genişliği
        { wch: 20 }, // T sütunu genişliği
        { wch: 10 }, // U sütunu genişliği
        { wch: 10 }, // V sütunu genişliği
        { wch: 10 }, // W sütunu genişliği
        { wch: 10 }, // X sütunu genişliği
        { wch: 5 }, // Y sütunu genişliği
        { wch: 10 }, // Z sütunu genişliği
        { wch: 8 }, // AA sütunu genişliği
        { wch: 10 }, // AB sütunu genişliği
        { wch: 10 }, // AC sütunu genişliği
        { wch: 100 }, // AD sütunu genişliği
        { wch: 50 }, // AE sütunu genişliği
        { wch: 50 }, // AF sütunu genişliği
        { wch: 10 }, // AG sütunu genişliği
        // ve böyle devam eder...
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const fileName = `${response.data}.xlsx`; // Dosya adını ve uzantısını belirtiyoruz
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDocumentNumber = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token"); // Token'ı localStorage'dan alabilirsiniz
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header'ını ekliyoruz
          },
        }
      );

      // Mevcut veri ile API'den gelen veriyi birleştiriyoruz

      setPdfData(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfModal(true);
    }
  };
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Finansal Kasalar", href: "/financialregisters" },
                { label: "Kasa Hareketleri", href: "" },
              ]}
            />
            <div className="m-12 min-h-screen">
              <div className="add-button">
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">
                    {data?.registerName}
                  </h1>
                </div>
                <div className="flex justify-between items-center pt-2">
                  <div className="flex gap-2">
                    <div>
                      <input
                        className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg pb-3"
                        type="text"
                        placeholder="Kasa hareket ara.."
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                    </div>
                  </div>
                  {/* <div className="flex gap-5 mr-4">
                    <a
                      onClick={fetchDocumentNumber}
                      className="font-medium text-[#050A27] hover:underline cursor-pointer"
                      title="PDF çıktı al"
                    >
                      <FaRegFilePdf
                        className="hover:scale-110 transition-transform duration-200"
                        size={30}
                      />
                    </a>
                    <button onClick={handleExportData}>
                      <a
                        title="Excel çıktı al"
                        className="font-medium text-[#050A27] hover:underline"
                      >
                        <RiFileExcel2Line
                          className="hover:scale-110 transition-transform duration-200"
                          size={35}
                        />
                      </a>
                    </button>
                  </div> */}
                </div>
              </div>

              <div className="relative overflow-x-auto mb-5">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-[#050A27]">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        ID
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Borç
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Alacak
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Kümüle Toplam
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Açıklama
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.accountCredits?.length > 0 ? (
                      data?.accountCredits
                        ?.filter((item) => {
                          const searchTermLower = searchTerm.toLowerCase();

                          return (
                            item.creditID
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTermLower) ||
                            item.borc
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTermLower) ||
                            item.alacak
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTermLower) ||
                            item.kumuluToplam
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTermLower) ||
                            item.description
                              ?.toLowerCase()
                              .includes(searchTermLower)
                          );
                        })
                        .map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b hover:bg-gray-50"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {item.creditID}
                            </th>
                            <td className="px-6 py-4">{item.borc}</td>
                            <td className="px-6 py-4">{item.alacak}</td>
                            <td className="px-6 py-4">{item.kumuluToplam}</td>
                            <td className="px-6 py-4">{item.description}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td
                          colSpan={6}
                          className="px-6 py-4 text-center text-2xl font-medium"
                        >
                          Henüz kayıtlı bir veri yoktur.
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={6}
                        className="px-6 py-4 text-right text-lg font-medium text-gray-900"
                      >
                        {data?.total < 0
                          ? `Borç: ${Math.abs(data.total)}`
                          : `Alacak: ${data.total}`}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      {openPdfModal === true && (
        <AccountMovementPdfModal
          data={data}
          pdfData={pdfData}
          onCancel={() => setOpenPdfModal(false)}
        />
      )}
      <Footer />
    </>
  );
};

export default FinancialRegisterMovements;
