import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api from "../../api/api";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";
/// REACT SPLIDE
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import doc from "../../assets/images/docimg.png";

const InvoiceEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [vat, setVat] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [stockCards, setStockCards] = useState([]);
  const [stockCardsOpt, setStockCardsOpt] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountsOpt, setAccountsOpt] = useState([]);
  const [title, setTitle] = useState("");
  const [invoiceName, setInvoiceName] = useState("");
  const [data, setData] = useState([]);
  const [token, setToken] = useState(null);
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [wareHouses, setWareHouses] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [file, setFile] = useState(null);
  const [dataImages, setDataImages] = useState(null);
  const [accountCredit, setAccountCredit] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [selectedRegister, setSelectedRegister] = useState({
    value: 0,
    label: "Kasa seçiniz...",
  });
  const [alacak, setAlacak] = useState(Number());

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=3`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
          .get(
            `/api/Register/GetRegisters?customerID=${user.country}&typeID=0&pageNumber=1&pageSize=99999`
          )
          .then((res) => {
            const newData = res.data?.items.map((item) => ({
              value: item.registerID,
              label: item.name,
            }));
            setRegisters(newData);
          })
          .catch((err) => console.log(err.data));


          api
            .get(
              `/api/Account/GetAccounts?customerID=${user.country}&typeID=25&pageNumber=1&pageSize=99999`
            )
            .then((res) => setAccounts(res.data.items))
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/WareHouse/GetWareHouses?customerID=${user.country}&pageNumber=1&pageSize=9999`
            )
            .then((res) => {
              console.log(res.data);
              const newDataWareHouses = res.data?.items.map((item) => ({
                value: item.wareHouseID,
                label: item.name,
              }));
              setWareHouses(newDataWareHouses);
            })
            .catch((err) => console.log(err));
          api
            .get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&typeIDs=28&typeIDs=29&typeIDs=1663&pageNumber=1&pageSize=99999`
            )
            .then((res) => setStockCards(res.data.items))
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetVat")
            .then((res) => setVat(res.data))
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetCurrencies")
            .then((res) => setCurrency(res.data));

          api
            .get(`/api/Invoice/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setTitle(res.data.title);
              setDataImages(res.data.imageFiles);
              setInvoiceName(res.data.name);
              setSelectedAccount({
                value: res.data.account.accountID,
                label: res.data.account.name,
              });
              setInvoiceItems(res.data.invoiceItems);
              setSelectedCurrency({
                value: res.data.currencyID,
                label: res.data.currencyName,
              });
              setData(res.data);
              setStartDate(new Date(res.data.invoiceDate));
              setFinishDate(new Date(res.data.expiryDate));
              setAlacak(res.data.totalPrice);
              setDescription(res.data.description);
              const data = JSON.parse(res.data.keywords);
              const newData = data.map((item) => ({
                value: item,
                label: item,
                __isNew__: true,
              }));
              setKeywords(newData);
              const accountID = res.data.accountID; 
              api
              .get(
                `/api/Account/GetAccountCreditByAccount?id=${accountID}&customerID=${user.country}`
              )
              .then((creditRes) => {
                setAccountCredit(creditRes.data);
              })
              .catch((creditErr) => {
                console.log(creditErr.data);
              });
            })

            .catch((err) => {
              console.log(err.data);
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  //ADD ITEMS///

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...invoiceItems];
    list[index][name] = value;

    //totalPrice
    let total =
      list[index].quantity * list[index].unitPrice - list[index].discount;
    let tax = (total * list[index].taxRate) / 100;
    let result = total + tax;
    list[index].totalPrice = result;

    //ara toplam
    let altToplam = list[index].quantity * list[index].unitPrice;
    list[index].price = altToplam;

    //KDV
    list[index].taxPrice = tax;

    setInvoiceItems(list);
  };

  const handleSelectWareHouseChange = (e, index) => {
    const list = [...invoiceItems];
    list[index].wareHouseID = e.value;
    list[index].wareHouseName = e.label;
    setInvoiceItems(list);
  };

  const handleSelectChange = (e, index) => {
    const list = [...invoiceItems];
    list[index].name = e.label;
    list[index].stockCardID = e.value;
    const stockCardsValue = stockCards.find(
      (item) => item.stockCardID === e.value
    );
    list[index].unitTypeString = stockCardsValue.unitTypeString;
    list[index].unitPrice = stockCardsValue.salesPrice;
    list[index].taxRate = stockCardsValue.vat;

    setInvoiceItems(list);
  };
  const handleSelectChangeVat = (e, index) => {
    const list = [...invoiceItems];
    list[index].taxRate = e;

    //totalPrice
    let total =
      list[index].quantity * list[index].unitPrice - list[index].discount;
    let tax = (total * list[index].taxRate) / 100;
    let result = total + tax;
    list[index].totalPrice = result;

    //KDV
    list[index].taxPrice = tax;

    setInvoiceItems(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...invoiceItems];
    list.splice(index, 1);
    setInvoiceItems(list);
  };

  const handleServiceAdd = () => {
    setInvoiceItems([
      ...invoiceItems,
      {
        quantity: Number(),
        unitPrice: Number(),
        discount: Number(),
        name: "",
        taxRate: "",
        totalPrice: Number(),
        price: "",
        taxPrice: "",
        description: "",
        invoiceItemID: Number(),
        invoiceID: params.id,
        customerID,
        stockCardID: Number(),
        wareHouseID: Number(),
      },
    ]);
  };

  //////////////////////////

  const find = accounts.find(
    (item) => item.accountID === selectedAccount.value
  );

  ////////calculations
  const resultDiscount = invoiceItems?.length
    ? invoiceItems
        .map((sayi) => Number(sayi.discount))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultTotalPrice = invoiceItems?.length
    ? invoiceItems
        .map((sayi) => Number(sayi.totalPrice))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultSubTotal = invoiceItems?.length
    ? invoiceItems
        .map((sayi) => Number(sayi.price))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultKdvTotal = invoiceItems?.length
    ? invoiceItems
        .map((sayi) => Number(sayi.taxPrice))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const postData = {
    invoiceID: params.id,
    customerID,
    accountID: selectedAccount.value,
    currencyID: selectedCurrency.value,
    invoiceNo: "",
    name: invoiceName,
    title: title,
    address: find?.address,
    taxOffice: find?.taxOffice,
    taxNumber: find?.taxNumber,
    invoiceDate: startDate,
    expiryDate: finishDate,
    price: resultSubTotal,
    lineDiscounts: resultDiscount,
    taxPrice: resultKdvTotal,
    totalPrice: resultTotalPrice,
    type: 0,
    status: 0,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    invoiceItems,
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .put("/api/Invoice/Put/Put", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Fatura başarılı bir şekilde değiştirildi ! ",
          showConfirmButton: false,
          timer: 3000,
        });
        navigate("/invoices");
      })

      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire(translatedError);
      })
      .finally(() => setLoading(false));
  };

  // FILE ONCHANGE
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const handleDocSubmit = async (e) => {
    setLoading(true);

    const formData = new FormData();
    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Dosyaları yükle (eğer varsa)
      if (file && file.length > 0) {
        await api.post(
          `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=108&relationID=${params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Dosyalar yüklendi!",
        showConfirmButton: false,
        timer: 1500,
      });

      // Başarı durumunda SweetAlert'ten sonra sayfanın yenilenmesi
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });

      // Hata durumunda SweetAlert'ten sonra sayfanın yenilenmesi (isteğe bağlı)
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleAccountCredit = async () => {
    setLoading(true);

    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    if (!token) {
      Swal.fire("Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }

    const creditObject = {
      customerID,
      accountID: data?.accountID,
      registerID: selectedRegister?.value,
      invoiceID: params.id,
      alacak,
      description: "",
    };

    try {
      const res = await api.post(
        `/api/Account/PostAccountCreditTahsilat/PostAccountCreditTahsilat`,
        creditObject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Tahsilat yapıldı!",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Satış Faturaları", href: "/invoices" },
                { label: "Satış Faturası Güncelle", href: "" },
              ]}
            />
             <div className="mx-auto my-20 rounded-[3px] min-h-screen flex justify-between w-[95%] gap-9">
             {data?.isPaid ? (
                <div className="absolute top-40 right-4 bg-green-500 text-white text-sm font-bold px-4 py-2 rounded">
                  Tahsil Edildi
                </div>
              ) : (
                <div className="absolute top-40 right-4 bg-red-500 text-white text-sm font-bold px-4 py-2 rounded">
                   Tahsil Edilmedi
                </div>
              )}
               <form
                onSubmit={handleSubmit}
                className={`bg-gray-100 p-12 ${
                  data.isPaid ? "w-full" : "w-[67%]"
                }`}
              >
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">
                  Satış Faturası Güncelle
                </h1>
              </div>
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Başlık
                  </label>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="invoiceName"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Adı
                  </label>
                  <input
                    value={invoiceName}
                    onChange={(e) => setInvoiceName(e.target.value)}
                    type="text"
                    id="invoiceName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Cari
                  </label>
                  <Select
                    maxMenuHeight={200}
                    id="name"
                    onChange={(e) => setSelectedAccount(e)}
                    value={selectedAccount}
                    options={accountsOpt}
                    placeholder="Seçiniz..."
                    classNamePrefix="custom-select"
                  />
                </div>

                <div>
                  <label
                    htmlFor="taxOffice"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Vergi Dairesi
                  </label>
                  <input
                    value={find?.taxOffice}
                    readOnly
                    type="text"
                    id="taxOffice"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="visitors"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Vergi No
                  </label>
                  <input
                    value={find?.taxNumber}
                    readOnly
                    type="text"
                    id="password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="visitors"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Anahtar Kelimeler
                  </label>
                  <CreatableSelect
                    value={keywords}
                    onChange={(e) => setKeywords(e)}
                    options={customerKey}
                    isMulti
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>

                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Para Birimi
                    </label>
                    <Select
                      maxMenuHeight={200}
                      onChange={(e) => setSelectedCurrency(e)}
                      value={selectedCurrency}
                      options={currency}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama
                  </label>
                  <input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    type="text"
                    id="description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="visitors"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Tarih
                  </label>
                  <DatePicker
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    locale={tr}
                    dateFormat={"dd/MM/yy"}
                  />
                </div>
                <div>
                  <label
                    htmlFor="visitors"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Ödeme Tarihi
                  </label>
                  <DatePicker
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    selected={finishDate}
                    onChange={(date) => setFinishDate(date)}
                    locale={tr}
                    dateFormat={"dd/MM/yy"}
                  />
                </div>
                <div className="flex items-center col-span-2">
                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                    id="multiple_files"
                    type="file"
                    multiple
                    onChange={handleChangeFile}
                  />
                  <button
                    type="button"
                    className="bg-gray-900  hover:bg-gray-700  text-white font-bold py-2 px-4 rounded-xl"
                    onClick={() => handleDocSubmit()}
                  >
                    Yükle
                  </button>
                </div>
                <div className="col-span-2 my-12">
                  <Splide
                    options={{
                      autoWidth: true,
                      gap: 100,
                      pagination: false,
                      padding: 100,
                    }}
                    aria-label="My Favorite Images"
                  >
                    {dataImages?.map((item, index) => {
                      const imageName = item.split("/").pop();
                      const altText = imageName.split("_").pop().split(".")[0];
                      // Resim URL'si kontrolü
                      const isImage = /\.(jpg|jpeg|png|gif)$/i.test(item);
                      return (
                        <SplideSlide key={index}>
                          {isImage ? (
                            <Link to={item}>
                              <img
                                className="max-w-[200px] cursor-pointer"
                                src={item}
                                alt={`Image ${index + 1}`}
                              />
                            </Link>
                          ) : (
                            <Link to={item}>
                              <img
                                className="max-w-[100px] cursor-pointer"
                                src={`${doc}`}
                              />
                            </Link>
                          )}
                          <p className="text-center font-medium">
                            {truncateText(altText, 10)}
                          </p>
                        </SplideSlide>
                      );
                    })}
                  </Splide>
                </div>
              </div>

              <div className="relative overflow-x-auto mb-5">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-[#050A27]">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Ürün Adı
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Miktar
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Birim Fiyat
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Birim Tipi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İskonto
                      </th>
                      <th scope="col" className="px-6 py-3">
                        KDV
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Toplam
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Depo
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İşlem
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceItems.map((singleService, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="px-6 py-4">
                          <div className="w-[180px]">
                            <Select
                              value={{
                                value: singleService.stockCardID,
                                label: singleService.name,
                              }}
                              maxMenuHeight={200}
                              onChange={(e) => handleSelectChange(e, index)}
                              options={stockCardsOpt}
                              classNamePrefix="custom-select"
                            />
                          </div>
                        </td>

                        <td className="px-6 py-4">
                          <input
                            value={singleService.quantity}
                            onChange={(e) => handleServiceChange(e, index)}
                            name="quantity"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                          />
                        </td>
                        <td className="px-6 py-4">
                          <input
                            value={singleService.unitPrice}
                            onChange={(e) => handleServiceChange(e, index)}
                            name="unitPrice"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                          />
                        </td>
                        <td className="px-6 py-4">
                          {singleService.unitTypeString ? (
                            <p className="text-[#0C692F] font-semibold text-center">
                              {singleService.unitTypeString}
                            </p>
                          ) : (
                            <p className="text-[#0C692F] font-semibold text-center">
                              -
                            </p>
                          )}
                        </td>
                        <td className="px-6 py-4">
                          <input
                            value={singleService.discount}
                            onChange={(e) => handleServiceChange(e, index)}
                            name="discount"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                          />
                        </td>
                        <td className="px-6 py-4">
                          <div className="w-[100px]">
                            <Select
                              maxMenuHeight={200}
                              value={{
                                value: singleService.taxRate,
                                label: singleService.taxRate,
                              }}
                              onChange={(e) =>
                                handleSelectChangeVat(e.value, index)
                              }
                              options={vat}
                              classNamePrefix="custom-select"
                              placeholder="Seçiniz..."
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <input
                            value={singleService.totalPrice}
                            readOnly
                            name="totalPrice"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                          />
                        </td>
                        <td className="px-6 py-4">
                          <div className="w-[150px]">
                            <Select
                              value={{
                                label: singleService.wareHouseName,
                                value: singleService.wareHouseID,
                              }}
                              options={wareHouses}
                              maxMenuHeight={200}
                              onChange={(e) =>
                                handleSelectWareHouseChange(e, index)
                              }
                              classNamePrefix="custom-select"
                              placeholder="Seçiniz..."
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <button
                            onClick={() => handleServiceRemove(index)}
                            className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-2 px-4 rounded-lg m-5"
                            title="Sil"
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <button
                    onClick={handleServiceAdd}
                    type="button"
                    className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg my-5 h-10 w-24"
                    title="Ekle"
                  >
                    Ekle
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </table>

                <div className="resultMoney">
                  <div className="resultMoneyInfo">
                    <h1>Ara Toplam :</h1>
                    <p>{resultSubTotal}</p>
                    <span>{selectedCurrency.label}</span>
                  </div>
                  <div className="resultMoneyInfo">
                    <h1>Satır İndirimi :</h1>
                    <p>{resultDiscount}</p>
                    <span>{selectedCurrency.label}</span>
                  </div>
                  <div className="resultMoneyInfo">
                    <h1>KDV :</h1>
                    <p>{resultKdvTotal}</p>
                    <span>{selectedCurrency.label}</span>
                  </div>
                  <div className="resultMoneyInfo">
                    <h1>Toplam :</h1>
                    <p>{resultTotalPrice}</p>
                    <span>{selectedCurrency.label}</span>
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                  title="Kaydet"
                >
                  Kaydet <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
            {data?.isPaid === false && (
                <div className="w-[33%] flex flex-col gap-10">
                  <div className="flex items-center gap-2 mb-4">
                    <FaStarOfLife size={15} />
                    <h1 className="font-semibold text-2xl">Tahsilat</h1>
                  </div>
                  <div className="mb-4 flex flex-col items-end gap-4 w-full">
                    <input
                      type="number"
                      placeholder="Tutar giriniz..."
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={alacak}
                      onChange={(e) => setAlacak(e.target.value)}
                      disabled={data?.totalPrice > accountCredit.total}
                    />
                    <div className="w-full">
                      <Select
                        maxMenuHeight={200}
                        classNamePrefix="custom-select"
                        placeholder="Seçiniz..."
                        options={registers}
                        value={selectedRegister}
                        onChange={(e) => setSelectedRegister(e)}
                        isDisabled={data?.totalPrice > accountCredit.total}
                      />
                    </div>
                    <button
                      onClick={handleAccountCredit}
                      disabled={data?.totalPrice > accountCredit.total}
                      className="text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 w-full"
                    >
                      Tahsilat Yap
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default InvoiceEdit;
