import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { setHours, setMinutes, setSeconds } from "date-fns";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const PersonsOvertimeAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [shiftDates, setShiftDates] = useState([]);
  const [shiftDate, setShiftDate] = useState(null);
  const [shiftTime, setShiftTime] = useState({});
  const [addHour, setAddHour] = useState({});
  const [workOrders, setWorkOrders] = useState([]);
  const [persons, setPersons] = useState([]);
  const [machines, setMachines] = useState([]);
  const [operations, setOperations] = useState([]);
  const [customerID, setCustomerID] = useState(Number());
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  //AGENDA ITEMS
  const [personShifts, setPersonShifts] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);

      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=26`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/Shift/GetCustomerShifts?customerID=${user.country}`)
            .then((res) => {
              const transformedShifts = res.data.map((shift) => ({
                value: `${shift.shiftStart}-${shift.shiftEnd}`,
                label: `${shift.shiftStart}-${shift.shiftEnd}`,
              }));
              setShiftDates(transformedShifts);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Machine/GetMachines?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataMachines = res.data.items;
              const newDataMachines = dataMachines?.map((item) => ({
                value: item.machineID,
                label: item.name,
              }));
              setMachines(newDataMachines);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Personel/GetPersons?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataPersons = res.data.items;
              const newDataPersons = dataPersons?.map((item) => ({
                value: item.personelID,
                label: `${item.name} ${item.surname}`,
              }));
              setPersons(newDataPersons);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/WorkOrder/GetWorkOrdersByStatusParam?customerID=${user.country}&status=1551&status=1552`
            )
            .then((res) => {
              const dataWorkOrder = res.data;
              const newDataWorkOrders = dataWorkOrder?.map((item) => ({
                value: item.workOrderID,
                label: item.name,
              }));
              setWorkOrders(newDataWorkOrders);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Operation/Getoperations?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataOperations = res.data.items;
              const newDataOperations = dataOperations?.map((item) => ({
                value: item.operationID,
                label: item.name,
              }));
              setOperations(newDataOperations);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleServiceAdd = () => {
    setPersonShifts([
      ...personShifts,
      {
        shiftID: null,
        personelID: null,
        machineID: null,
        operationID: null,
        workOrderID: null,
        workOrderItemID: null,
        personelName: "",
        machineName: "",
        operationName: "",
        workOrderName: "",
        workOrderItemName: "",
      },
    ]);
  };

  const handlePersonChange = (e, index) => {
    const list = [...personShifts];
    list[index].personelID = e.value;
    list[index].personelName = e.label;
    setPersonShifts(list);
  };
  const handleMachineChange = (e, index) => {
    const list = [...personShifts];
    list[index].machineID = e.value;
    list[index].machineName = e.label;
    list[index].operationID = 0;
    setPersonShifts(list);
  };
  const handleOperationChange = (e, index) => {
    const list = [...personShifts];
    list[index].operationID = e.value;
    list[index].operationName = e.label;
    list[index].machineID = 0;
    setPersonShifts(list);
  };
  const handleWorkOrderChange = async (e, index) => {
    setLoading(true);
    const list = [...personShifts];
    list[index].workOrderID = e.value;
    list[index].workOrderName = e.label;
    setPersonShifts(list);

    await api
      .get(
        `/api/WorkOrder/GetWorkOrderItems?customerID=${customerID}&workOrderID=${e.value}`
      )
      .then((res) => {
        list[index].workOrderItemOpt = res.data.map((item) => ({
          value: item.workOrderItemID,
          label: item.name,
        }));
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  };

  const handleWorkOrderItemChange = (e, index) => {
    const list = [...personShifts];
    list[index].workOrderItemID = e.value;
    list[index].workOrderItemName = e.label;
    setPersonShifts(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...personShifts];
    list.splice(index, 1);
    setPersonShifts(list);
  };

  const getShiftPersonsForOvertime = async () => {
    if (!shiftDate || !shiftTime.value) {
      Swal.fire({
        icon: "warning",
        title: "Eksik Bilgi",
        text: "Lütfen tarihi ve vardiyayı seçin.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Tamam",
      });
      return;
    }

    setLoading(true);
    try {
      const formattedDate = formatDate(shiftDate);
      const response = await api.get(
        `/api/Shift/GetShiftPersonsForOverTime?customerID=${customerID}&shiftDate=${formattedDate}&shiftTime=${shiftTime.value}`
      );
      setPersonShifts(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const postData = {
    customerID,
    shiftDate: formatDate(shiftDate),
    shiftTime: shiftTime.value,
    _lst: personShifts?.map((item) => ({
      personelID: item.personelID,
      machineID: item.machineID,
      operationID: item.operationID,
      workOrderID: item.workOrderID,
      workOrderItemID: item.workOrderItemID,
    })),
    addHour : addHour?.value,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post(
        "/api/Shift/PostShiftOverTime/PostShiftOverTime",
        postData
      );

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Mesai eklendi !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/personelShifts");
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  console.log(personShifts);
  console.log("post",postData);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Vardiya Takvimi", href: "/personelShifts" },
                { label: "Mesai Ekle", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">Mesai Ekle</h1>
              </div>
              <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-12 items-center w-full mt-5">
                <div className="col-span-1">
                  <label
                    htmlFor="shiftDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Tarih :
                  </label>
                  <DatePicker
                    id="shiftDate"
                    placeholder="Seçiniz..."
                    selected={shiftDate}
                    onChange={(date) => setShiftDate(date)}
                    locale={tr}
                    dateFormat="dd/MM/yyyy"
                    wrapperClassName="w-full" // Wrapper genişliğini kontrol eder
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
                  />
                </div>

                <div className="col-span-1">
                  <label
                    htmlFor="endDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Vardiya :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                    options={shiftDates}
                    value={shiftTime}
                    onChange={(e) => setShiftTime(e)}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="shiftDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Yapılacak Vardiya :
                  </label>

                  <Select
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                    value={addHour}
                    onChange={(e) => setAddHour(e)}
                    options={[
                      { value: 1, label: 1 },
                      { value: 2, label: 2 },
                      { value: 3, label: 3 },
                      { value: 4, label: 4 },
                      { value: 5, label: 5 },
                      { value: 6, label: 6 },
                    ]}
                  />
                </div>
                {personShifts.length === 0 && (
                  <div>
                    <button
                      type="button"
                      className="bg-[#0C692F] hover:bg-[#0c6930e3] text-white font-semibold py-2 px-4 rounded-lg h-10"
                      onClick={getShiftPersonsForOvertime}
                    >
                      Personelleri Getir
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </div>
                )}

                <div className="relative overflow-x-auto mb-5 w-full overflow-y-auto col-span-2 min-h-[400px]">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-3 py-3">
                          Personel
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Makineler
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Operasyonlar
                        </th>
                        <th scope="col" className="px-3 py-3">
                          İş Emri
                        </th>
                        <th scope="col" className="px-3 py-3">
                          İş Emri Kalemi
                        </th>
                        <th scope="col" className="px-3 py-3">
                          İşlemler
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {personShifts?.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-3 py-4">
                            <Select
                              maxMenuHeight={200}
                              classNamePrefix="custom-select"
                              placeholder="Seçiniz..."
                              options={persons}
                              value={{
                                value: singleService.personelID,
                                label: singleService.personelName,
                              }}
                              onChange={(e) => handlePersonChange(e, index)}
                            />
                          </td>
                          <td className="px-3 py-4">
                            <Select
                              maxMenuHeight={200}
                              classNamePrefix="custom-select"
                              placeholder="Seçiniz..."
                              isDisabled={singleService.machineID === 0}
                              options={machines}
                              value={{
                                value: singleService.machineID,
                                label: singleService.machineName,
                              }}
                              onChange={(e) => handleMachineChange(e, index)}
                            />
                          </td>
                          <td className="px-3 py-4">
                            <Select
                              maxMenuHeight={200}
                              classNamePrefix="custom-select"
                              placeholder="Seçiniz..."
                              isDisabled={singleService.operationID === 0}
                              options={operations}
                              value={{
                                value: singleService.operationID,
                                label: singleService.operationName,
                              }}
                              onChange={(e) => handleOperationChange(e, index)}
                            />
                          </td>
                          <td className="px-3 py-4">
                            <Select
                              maxMenuHeight={200}
                              classNamePrefix="custom-select"
                              placeholder="Seçiniz..."
                              options={workOrders}
                              value={{
                                value: singleService.workOrderID,
                                label: singleService.workOrderName,
                              }}
                              onChange={(e) => handleWorkOrderChange(e, index)}
                            />
                          </td>
                          <td className="px-3 py-4">
                            <Select
                              maxMenuHeight={200}
                              classNamePrefix="custom-select"
                              placeholder="Seçiniz..."
                              options={singleService?.workOrderItemOpt}
                              onChange={(e) =>
                                handleWorkOrderItemChange(e, index)
                              }
                              value={{
                                value: singleService.workOrderItemID,
                                label: singleService.workOrderItemName,
                              }}
                            />
                          </td>

                          <td className="px-3 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <button
                      type="button"
                      className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg my-5 h-10 w-24"
                      onClick={handleServiceAdd}
                    >
                      Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </table>
                </div>

                <div className="col-span-2 flex justify-end gap-[20px]">
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PersonsOvertimeAdd;
