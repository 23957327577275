import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";

const waitingBetweenShiftOpt = Array.from({ length: 60 }, (_, i) => ({
  value: i,
  label: `${i} dakika`,
}));

const weeklyWorkingHours = Array.from({ length: 75 }, (_, i) => ({
  value: i + 10,
  label: `${i + 10} saat`,
}));

const ShiftInformationTab = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [customerID, setCustomerID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [numberOfShiftPerDay, setNumberOfShiftPerDay] = useState({
    value: 0,
    label: "Seçiniz...",
  });
  const [jobTrackinkDepth, setJobTrackinkDepth] = useState({
    value: 0,
    label: "Seçiniz...",
  });
  const [weekWorkingDays, setWeekWorkingDays] = useState(0);
  const [waitingBetweenShiftPerMinutes, setWaitingBetweenShiftPerMinutes] =
    useState({ value: 0, label: "Seçiniz..." });
  const [weeklyPersonWorkingPerHours, setWeeklyPersonWorkingPerHours] =
    useState({ value: 0, label: "Seçiniz..." });
  const [shifts, setShifts] = useState({
    shift1_Start: "",
    shift1_End: "",
    shift2_Start: "",
    shift2_End: "",
    shift3_Start: "",
    shift3_End: "",
    shift4_Start: "",
    shift4_End: "",
  });
  const [workingDays, setWorkingDays] = useState({
    day1: false,
    day2: false,
    day3: false,
    day4: false,
    day5: false,
    day6: false,
    day7: false,
  });

  const days = [
    { id: "day1", label: "Pazartesi" },
    { id: "day2", label: "Salı" },
    { id: "day3", label: "Çarşamba" },
    { id: "day4", label: "Perşembe" },
    { id: "day5", label: "Cuma" },
    { id: "day6", label: "Cumartesi" },
    { id: "day7", label: "Pazar" },
  ];

  useEffect(() => {
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=21`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(`/api/Customer/GetCustomerShift?id=${user.country}`)
            .then((res) => {
              console.log("res", res.data);

              setNumberOfShiftPerDay({
                value: res.data.numberOfShiftPerDay,
                label: res.data.numberOfShiftPerDay,
              });
              setShifts({
                shift1_Start: res.data.shift1_Start,
                shift1_End: res.data.shift1_End,
                shift2_Start: res.data.shift2_Start,
                shift2_End: res.data.shift2_End,
                shift3_Start: res.data.shift3_Start,
                shift3_End: res.data.shift3_End,
                shift4_Start: res.data.shift4_Start,
                shift4_End: res.data.shift4_End,
              });
              setWaitingBetweenShiftPerMinutes({
                value: res.data.waitingBetweenShiftPerMinutes,
                label: `${res.data.waitingBetweenShiftPerMinutes} dakika`,
              });
              setWeeklyPersonWorkingPerHours({
                value: res.data.weeklyPersonWorkingPerHours,
                label: `${res.data.weeklyPersonWorkingPerHours} saat`,
              });
              setJobTrackinkDepth({
                value: res.data.jobTrackinkDepth,
                label: `${res.data.jobTrackinkDepth} dakika`,
              });
              setWeekWorkingDays(res.data.weekWorkingDays);
              setWorkingDays({
                day1: res.data.day1,
                day2: res.data.day2,
                day3: res.data.day3,
                day4: res.data.day4,
                day5: res.data.day5,
                day6: res.data.day6,
                day7: res.data.day7,
              });
            })
            .catch((err) => console.error(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  useEffect(() => {
    // Seçili günleri hesapla
    const selectedDaysCount = Object.values(workingDays).filter(
      (selected) => selected
    ).length;

    // `weekWorkingDays` state'ini güncelle
    setWeekWorkingDays(selectedDaysCount);
  }, [workingDays]);

  const handleShiftChange = (field, value) => {
    setShifts((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDayChange = (dayId) => {
    setWorkingDays((prev) => ({
      ...prev,
      [dayId]: !prev[dayId],
    }));
  };
  const postData = {
    customerID,
    numberOfShiftPerDay: numberOfShiftPerDay.value,
    waitingBetweenShiftPerMinutes: waitingBetweenShiftPerMinutes.value,
    weeklyPersonWorkingPerHours: weeklyPersonWorkingPerHours.value,
    jobTrackinkDepth: jobTrackinkDepth.value,
    weekWorkingDays,
    shift1_Start: shifts.shift1_Start,
    shift1_End: shifts.shift1_End,
    shift2_Start: shifts.shift2_Start,
    shift2_End: shifts.shift2_End,
    shift3_Start: shifts.shift3_Start,
    shift3_End: shifts.shift3_End,
    shift4_Start: shifts.shift4_Start,
    shift4_End: shifts.shift4_End,
    day1: workingDays.day1,
    day2: workingDays.day2,
    day3: workingDays.day3,
    day4: workingDays.day4,
    day5: workingDays.day5,
    day6: workingDays.day6,
    day7: workingDays.day7,
  };

  console.log(postData);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .put("/api/Customer/PutCustomerShift/PutCustomerShift", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Vardiya bilgileri güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      })

      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-full bg-gray-100">
          <div className="mb-5 flex items-center gap-2">
            <FaStarOfLife size={15} />
            <h1 className="text-3xl font-semibold title">Vardiya Bilgileri</h1>
          </div>
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-2 gap-7 items-center w-full"
          >
            <div>
              <label
                htmlFor=""
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Günlük Vardiya Sayısı :
              </label>
              <Select
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                placeholder="Seçiniz..."
                options={[
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                ]}
                onChange={(e) => setNumberOfShiftPerDay(e)}
                value={numberOfShiftPerDay}
              />
            </div>
            <div>
              <label
                htmlFor=""
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Üretim Planlama Takip Derinliği :
              </label>
              <Select
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                placeholder="Seçiniz..."
                options={[
                  { label: "1 saat", value: 1 },
                  { label: "15 dakika", value: 15 },
                  { label: "30 dakika", value: 30 },
                  { label: "1 dakika", value: 60 },
                ]}
                onChange={(e) => setJobTrackinkDepth(e)}
                value={jobTrackinkDepth}
              />
            </div>

            <div>
              <label
                htmlFor=""
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Vardiyalar Arası Bekleme :
              </label>
              <Select
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                placeholder="Seçiniz..."
                options={waitingBetweenShiftOpt}
                value={waitingBetweenShiftPerMinutes}
                onChange={(e) => setWaitingBetweenShiftPerMinutes(e)}
              />
            </div>
            <div>
              <label
                htmlFor=""
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Personel Haftalık Çalışma Saati :
              </label>
              <Select
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                placeholder="Seçiniz..."
                options={weeklyWorkingHours}
                value={weeklyPersonWorkingPerHours}
                onChange={(e) => setWeeklyPersonWorkingPerHours(e)}
              />
            </div>

            {Array.from({ length: numberOfShiftPerDay.value }).map(
              (_, index) => (
                <div key={index} className="col-span-2 mt-6">
                  <label
                    htmlFor=""
                    className="block mb-4 text-sm font-medium text-gray-900"
                  >
                    {index + 1}- Vardiya Saatleri :
                  </label>
                  <div className="grid grid-cols-2 gap-12">
                    <div className="flex items-center gap-3">
                      <label
                        htmlFor=""
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Başlangıç:
                      </label>
                      <input
                        type="time"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        value={shifts[`shift${index + 1}_Start`]?.slice(0, 5)}
                        onChange={(e) => {
                          const newTime = e.target.value;
                          const formattedTime = `${newTime}:00`; 
                          handleShiftChange(
                            `shift${index + 1}_Start`,
                            formattedTime
                          );
                        }}
                      />
                    </div>
                    <div className="flex items-center gap-3">
                      <label
                        htmlFor=""
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Bitiş:
                      </label>
                      <input
                        type="time"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        value={shifts[`shift${index + 1}_End`]?.slice(0, 5)}
                        onChange={(e) => {
                          const newTime = e.target.value;
                          const formattedTime = `${newTime}:00`;
                          handleShiftChange(
                            `shift${index + 1}_End`,
                            formattedTime
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 pb-3">
                Çalışma Günleri :
              </label>
              <div className="grid grid-cols-4 gap-4">
                {days.map((day) => (
                  <div key={day.id} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id={day.id}
                      name={day.label}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      checked={workingDays[day.id]}
                      onChange={() => handleDayChange(day.id)}
                    />
                    <label
                      htmlFor={day.id}
                      className="text-sm font-medium text-gray-900"
                    >
                      {day.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-2 flex justify-end gap-[20px]">
              <button
                type="submit"
                className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
              >
                Kaydet
                <i className="fa-regular fa-floppy-disk ml-2"></i>
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ShiftInformationTab;
