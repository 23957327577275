import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import Timeline from "react-calendar-timeline";
import moment from "moment";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { FiPackage } from "react-icons/fi";
import { GiCog } from "react-icons/gi";
import { MdOutlineScience } from "react-icons/md";
import { FaPencilRuler } from "react-icons/fa";
import { GiToolbox } from "react-icons/gi";
import { RiCustomerService2Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const options = [
  { value: -1, label: "Mevcut iş emrinin üzerine yaz" },
  { value: -2, label: "Mevcut iş emrini sonraya ötele" },
  { value: -3, label: "İş Emrini Mevcut iş emrinin sonrasına ekle" },
];

const WorkOrderAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [token, setToken] = useState(null);
  const [openModule, setOpenModule] = useState(false);
  const [orders, setOrders] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountsOpt, setAccountsOpt] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [title, setTitle] = useState("");
  const [ordersOption, setOrdersOption] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [operations, setOperations] = useState([]);
  const [machines, setMachines] = useState([]);
  const [wareHouses, setWareHouses] = useState([]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [productionStockCard, setProductionStockCard] = useState([]);
  const [allSuccess, setAllSuccess] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=27`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          // api
          //   .get(
          //     `/api/Order/GetOrdersByStatus?customerID=${user.country}&statusID=1547`
          //   )
          //   .then((res) => {
          //     setOrders(res.data);
          //     const order = res.data?.map((item) => ({
          //       value: item.orderID,
          //       label: item.name,
          //       value2: item?.accountID,
          //     }));
          //     setOrdersOption(order);
          //   })
          //   .catch((err) => console.log(err));

          api
            .get(
              `/api/Account/GetAccounts?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setAccounts(res.data.items);
              const newDataAccounts = res.data.items?.map((item) => ({
                value: item.accountID,
                label: item.name,
              }));
              setAccountsOpt(newDataAccounts);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Machine/GetMachines?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setMachines(res.data.items);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Operation/Getoperations?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setOperations(res.data.items);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/WareHouse/GetWareHouses?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const newDataWareHouse = res.data.items?.map((item) => ({
                value: item.wareHouseID,
                label: item.name,
              }));
              setWareHouses(newDataWareHouse);
            })
            .catch((err) => console.log(err));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newDataKey = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setKeywordsData(newDataKey);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const getStockCardProduction = async (e) => {
    if (startDate === undefined || endDate === undefined) {
      Swal.fire("Lütfen tarih aralığını seçiniz!");
    } else {
      setLoading(true);
      setSelectedOrder(e);
      setOpenModule(true);

      // accounts state'inizden e.value ile eşleşen account'ı bulun
      const selectedAccount = accounts.find(
        (account) => account.accountID === e.value2
      );

      // Eğer bir eşleşme bulunduysa setAccountName fonksiyonunu çağır
      if (selectedAccount) {
        setAccountName(selectedAccount.name); // account objesindeki name propertysini set ediyoruz
      } else {
        console.error("No matching account found for the provided value");
      }

      await api
        .get(
          `/api/StockCardBOM/GetStockCardBOMProductionStockCard?customerID=${customerID}&orderID=${e.value}`
        )
        .then((res) => {
          console.log("resdata", res.data);

          // API'den gelen verileri map ile dönüştürme
          const transformedData = res.data.map((item) => ({
            ...item,
            wareHouseID: { value: item.wareHouseID, label: item.wareHouseName },
          }));
          setProductionStockCard(transformedData);
        })
        .catch((error) => {
          const errorMessageKey = error.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${translatedError}`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleServiceQuantitySelectChange = (e, index) => {
    const list = [...productionStockCard];
    list[index].quantity = e;
    setProductionStockCard(list);
  };

  const handleServiceStatusSelectChange = async (e, index, singleService) => {
    setLoading(true);

    const list = [...productionStockCard];
    list[index].status = e;

    // e.value -3 değilse response.success'u true yap ve fonksiyonu sonlandır
    if (e.value !== -3) {
      list[index].response = {
        ...list[index].response,
        success: true,
        errorMessage: "Üretim Planlamaya Uygun",
      };
      setProductionStockCard(list);
      setLoading(false);
      return;
    }

    const formattedStartDate = new Date(
      singleService.response.availableStartDate
    ).toISOString(); // ISO formatında tarih ve saat

    // isMachineProduct'a göre machineOperation değerini belirleyelim
    const machineOperation = singleService.isMachineProduct ? 1 : 2;

    await api
      .get(
        `/api/WorkOrder/ReCalculateProductionTime?stockCardID=${
          singleService.stockCardID
        }&customerID=${customerID}&machineOperationID=${
          singleService.selectedOperation?.value ||
          singleService.selectedMachine?.value
        }&machineOperation=${machineOperation}&quantity=${
          singleService.quantity
        }&startDate=${formattedStartDate}`
      )
      .then((res) => {
        const list2 = [...productionStockCard];
        list2[index].startDate = new Date(res.data.startDate);
        list2[index].endDate = new Date(res.data.endDate);
        list2[index].response = res.data;

        setProductionStockCard(list2);
        // API yanıtı başarılıysa handleStartDate fonksiyonunu çağır
        handleStartDate(new Date(res.data.startDate), index, singleService);
      })
      .catch((error) => {
        const errorMessageKey = error.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMachineChange = async (selectedOption, index) => {
    setLoading(true);
    const list = [...productionStockCard];
    list[index].selectedMachine = selectedOption;
    setProductionStockCard(list);

    // Tarih ve saat bilgilerini doğru şekilde ayarlayalım
    const formattedStartDate = new Date(startDate).toISOString(); // ISO formatında tarih ve saat
    const formattedEndDate = new Date(endDate).toISOString(); // ISO formatında tarih ve saat

    await api
      .get(
        `/api/WorkOrder/GetShiftPlaningByMachineID?customerID=${customerID}&machineID=${selectedOption.value}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      )
      .then((res) => {
        const list2 = [...productionStockCard];
        list2[index].shiftPlanning = res.data;
        setProductionStockCard(list2);
      })
      .catch((error) => {
        const errorMessageKey = error.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOperationChange = async (selectedOption, index) => {
    setLoading(true);
    const list = [...productionStockCard];
    list[index].selectedOperation = selectedOption;
    setProductionStockCard(list);

    const formattedStartDate = new Date(startDate).toISOString(); // ISO formatında tarih ve saat
    const formattedEndDate = new Date(endDate).toISOString(); // ISO formatında tarih ve saat

    await api
      .get(
        `/api/WorkOrder/GetShiftPlaningByOperationID?customerID=${customerID}&operationID=${selectedOption.value}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      )
      .then((res) => {
        const list3 = [...productionStockCard];
        list3[index].shiftPlanning = res.data;
        setProductionStockCard(list3);
      })
      .catch((error) => {
        const errorMessageKey = error.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleWareHouse = async (selectedOption, index) => {
    const list = [...productionStockCard];
    list[index].wareHouseID = selectedOption;
    setProductionStockCard(list);
  };

  const handleStartDate = async (selectedOption, index, singleService) => {
    console.log("singleService", singleService);
    const formattedStartDate = new Date(selectedOption).toISOString(); // ISO formatında tarih ve saat

    const list = [...productionStockCard];
    list[index].startDate = selectedOption;

    try {
      setLoading(true);
      let response;
      if (singleService.isMachineProduct) {
        response = await api.get(
          `/api/WorkOrder/CalculateProductionTimeMachine?stockCardID=${singleService.stockCardID}&machineID=${singleService.selectedMachine.value}&customerID=${customerID}&quantity=${singleService.quantity}&startDate=${formattedStartDate}`
        );
      } else if (singleService.isOperationProduct) {
        response = await api.get(
          `/api/WorkOrder/CalculateProductionTimeOperation?stockCardID=${singleService.stockCardID}&operationID=${singleService.selectedOperation.value}&customerID=${customerID}&quantity=${singleService.quantity}&startDate=${formattedStartDate}`
        );
      }

      // API'den dönen veriye göre uygun alanı güncelleyin
      if (response) {
        console.log(response.data);

        list[index].response = response.data;
        list[index].endDate = new Date(response.data.endDate);
      }
    } catch (error) {
      console.error("Error fetching production time:", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }

    setProductionStockCard(list);
  };

  const handleEndDate = async (selectedOption, index) => {
    const list = [...productionStockCard];
    list[index].endDate = selectedOption;
    setProductionStockCard(list);
  };

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  const handleItemClick = (itemId) => {
    let selectedItem = null;
    // productionStockCard dizisindeki her bir öğeyi kontrol et
    for (const item of productionStockCard) {
      // Her bir öğenin shiftPlanning özelliğinin içindeki shiftID'leri kontrol et
      for (const shiftId in item.shiftPlanning) {
        // Eğer shiftID, itemId'ye eşitse, bu öğeyi seçili olarak işaretle ve döngüyü durdur
        if (item.shiftPlanning[shiftId].shiftID === itemId) {
          selectedItem = item.shiftPlanning[shiftId];
          break;
        }
      }
      // Eğer selectedItem bulunduysa, döngüyü durdur
      if (selectedItem) {
        Swal.fire({
          title: `${selectedItem.workOrderNames}`,
          html: `Baş.Tarihi:<span class="font-bold text-green-600"> ${formatDate(
            selectedItem.startDate
          )}</span><br>Bit.Tarih: <span class="font-bold text-green-600">${formatDate(
            selectedItem.endDate
          )}</span>`,
        });
      }
    }
  };

  const handleGetOrdersByStatusAcc = async (e) => {
    setLoading(true);
    setSelectedAccount(e);
    await api
      .get(
        `/api/Order/GetOrdersByStatusAccount?customerID=${customerID}&statusID=1547&accountID=${e.value}`
      )
      .then((res) => {
        setOrders(res.data);
        const order = res.data?.map((item) => ({
          value: item.orderID,
          label: item.name,
          value2: item?.accountID,
        }));
        setOrdersOption(order);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const postData = {
    customerID,
    orderID: selectedOrder.value,
    name,
    title,
    startDate,
    endDate,
    actualStartDate: "",
    actualEndDate: "",
    actualProtuctionMinute: 0,
    plannedProductionQuantity: 0,
    actualProductionQuantity: 0,
    type: 1680,
    status: 0,
    viewIndex: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    notes,
    workOrderItems: productionStockCard?.map((item) => ({
      stockCardID: item.stockCardID,
      machineID: item.selectedMachine ? item.selectedMachine.value : 0,
      operationID: item.selectedOperation ? item.selectedOperation.value : 0,
      wareHouseID: item.wareHouseID?.value,
      name: item.name,
      startDate: item.startDate,
      endDate: new Date(item.endDate),
      plannedProductionQuantity: Number(item.quantity),
      status: item.status?.value,
    })),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.post("/api/WorkOrder/Post/Post", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "İş Emri oluşturuldu !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/workOrders");
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  const iconMap = {
    28: { icon: FiPackage, title: "Mamul" },
    29: { icon: GiCog, title: "Yarı Mamul" },
    30: { icon: MdOutlineScience, title: "Hammadde" },
    54: { icon: FaPencilRuler, title: "Sarf Malzeme" },
    1544: { icon: GiToolbox, title: "Yardımcı Malzeme" },
    1663: { icon: RiCustomerService2Line, title: "Hizmet" },
  };

  useEffect(() => {
    // isOperationProduct veya isMachineProduct true olan ve type'ı 29 olmayanların sayısını hesapla
    const totalValidItems = productionStockCard.filter(
      (item) =>
        (item.isOperationProduct === true || item.isMachineProduct === true) &&
        item.type !== 29
    ).length;

    // item.response?.success true olan ve type'ı 29 olmayanların sayısını hesapla
    const totalSuccessItems = productionStockCard.filter(
      (item) => item.response?.success === true && item.type !== 29
    ).length;

    // Her iki sayıyı karşılaştır ve eşitse enable et
    setAllSuccess(totalValidItems === totalSuccessItems);
  }, [productionStockCard]);

  console.log(productionStockCard);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Üretim Planlama", href: "/workOrders" },
                { label: "Sipariş'ten Üretim Planlama Oluştur", href: "" },
              ]}
            />

            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen  w-[90%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">
                  Sipariş'ten Üretim Planlama Oluştur
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-4 gap-7 items-center w-full"
              >
                <div className="col-span-2">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Üretim Planlama Başlığı :
                  </label>
                  <input
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Üretim Planlama Adı :
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="startDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Başlangıç Tarih :
                  </label>
                  <DatePicker
                    id="startDate"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    showTimeSelect
                    locale={tr}
                    dateFormat="dd/MM/yyyy - HH:mm"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="endDate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Bitiş Tarih :
                  </label>
                  <DatePicker
                    id="endDate"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    showTimeSelect
                    locale={tr}
                    dateFormat="dd/MM/yyyy - HH:mm"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
                <div className="col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Cari Seç :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    options={accountsOpt}
                    value={selectedAccount}
                    onChange={(e) => handleGetOrdersByStatusAcc(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                <div className="col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Sipariş Seç :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    options={ordersOption}
                    value={selectedOrder}
                    onChange={(e) => getStockCardProduction(e)}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>
                {/* <div className="col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Müşteri :
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    disabled
                    value={accountName}
                  />
                </div> */}

                <div className="col-span-4">
                  <label
                    htmlFor="keywords"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Anahtar Kelimeler :
                  </label>
                  <CreatableSelect
                    isMulti
                    maxMenuHeight={200}
                    id="keywords"
                    onChange={(e) => setKeywords(e)}
                    options={keywordsData}
                    classNamePrefix="custom-select"
                    placeholder="Seçiniz..."
                  />
                </div>

                <div className="col-span-3">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <textarea
                    id="description"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="note"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Not :
                  </label>
                  <textarea
                    id="note"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    rows={4}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>

                {openModule && (
                  <div className=" overflow-x-auto w-full overflow-y-auto col-span-4 h-full">
                    <table className="w-full text-sm text-left text-gray-500">
                      <thead className="text-xs text-white uppercase bg-[#050A27]">
                        <tr>
                          <th scope="col" className="px-3 py-3"></th>
                          <th scope="col" className="px-3 py-3">
                            İş Emri Kalemleri
                          </th>
                          <th scope="col" className="px-3 py-3">
                            Makine / Operasyon
                          </th>
                          <th scope="col" className="px-3 py-3">
                            Depo
                          </th>
                          <th scope="col" className="px-3 py-3">
                            Adet
                          </th>
                          <th scope="col" className="px-3 py-3">
                            Baş. Tarih
                          </th>
                          <th scope="col" className="px-3 py-3">
                            Bit.Tarih
                          </th>
                          <th scope="col" className="px-3 py-3">
                            Durum
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {productionStockCard?.map((singleService, index) => (
                          <React.Fragment key={index}>
                            <tr className="bg-white border-b">
                              <td className="px-3 py-4 text-center">
                                {iconMap[singleService.type] ? (
                                  // İkonu dinamik olarak render et ve title ile aria-label ekle
                                  React.createElement(
                                    iconMap[singleService.type].icon,
                                    {
                                      size: 24,
                                      title: iconMap[singleService.type].title,
                                      "aria-label":
                                        iconMap[singleService.type].title, // Erişilebilirlik için
                                    }
                                  )
                                ) : (
                                  <span>-</span> // Eğer eşleşen ikon yoksa
                                )}
                              </td>
                              <td className="px-3 py-4">
                                <input
                                  name="name"
                                  type="text"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                  disabled
                                  value={`${singleService.code} - ${singleService.name}`}
                                />
                              </td>
                              {singleService.isMachineProduct &&
                                singleService.type !== 29 && (
                                  <td className="px-3 py-4">
                                    <Select
                                      options={
                                        singleService.machines.length > 0
                                          ? singleService.machines.map(
                                              (item) => ({
                                                label: item.name,
                                                value: item.machineID,
                                              })
                                            )
                                          : machines.map((item) => ({
                                              label: item.name,
                                              value: item.machineID,
                                            }))
                                      }
                                      maxMenuHeight={200}
                                      onChange={(e) =>
                                        handleMachineChange(e, index)
                                      }
                                      value={singleService?.selectedMachine}
                                      required
                                      menuPortalTarget={document.body} // Menüyü body içinde render eder
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                        control: (base) => ({
                                          ...base,
                                          width: "200px", // Set fixed width
                                        }),
                                      }}
                                      classNamePrefix="custom-select"
                                      placeholder="Seçiniz..."
                                    />
                                  </td>
                                )}

                              {singleService.isOperationProduct &&
                                singleService.type !== 29 && (
                                  <td className="px-3 py-4">
                                    <Select
                                      options={
                                        singleService.operations.length > 0
                                          ? singleService.operations.map(
                                              (item) => ({
                                                label: item.name,
                                                value: item.operationID,
                                              })
                                            )
                                          : operations.map((item) => ({
                                              label: item.name,
                                              value: item.operationID,
                                            }))
                                      }
                                      maxMenuHeight={200}
                                      onChange={(e) =>
                                        handleOperationChange(e, index)
                                      }
                                      value={singleService?.selectedOperation}
                                      required
                                      menuPortalTarget={document.body} // Menüyü body içinde render eder
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                        control: (base) => ({
                                          ...base,
                                          width: "200px", // Set fixed width
                                        }),
                                      }}
                                      classNamePrefix="custom-select"
                                      placeholder="Seçiniz..."
                                    />
                                  </td>
                                )}
                              {((!singleService.isOperationProduct &&
                                !singleService.isMachineProduct) ||
                                singleService.type === 29) && (
                                <td className="px-3 py-4">
                                  <div></div>
                                </td>
                              )}

                              <td className="px-3 py-4">
                                <Select
                                  onChange={(e) => handleWareHouse(e, index)}
                                  value={singleService?.wareHouseID}
                                  options={wareHouses}
                                  maxMenuHeight={200}
                                  menuPortalTarget={document.body} // Menüyü body içinde render eder
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                    control: (base) => ({
                                      ...base,
                                      width: "200px", // Set fixed width
                                    }),
                                  }}
                                  classNamePrefix="custom-select"
                                  placeholder="Seçiniz..."
                                />
                              </td>
                              <td className="px-3 py-4">
                                <input
                                  name="quantity"
                                  type="number"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
                                  required
                                  value={singleService.quantity}
                                  onChange={(e) => {
                                    handleServiceQuantitySelectChange(
                                      e.target.value,
                                      index
                                    );
                                  }}
                                />
                              </td>
                              <td className="px-3 py-4">
                                <DatePicker
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                  showTimeSelect
                                  locale={tr}
                                  dateFormat="dd/MM/yyyy - HH:mm"
                                  timeIntervals={15}
                                  selected={singleService.startDate}
                                  onChange={(e) => {
                                    handleStartDate(e, index, singleService);
                                  }}
                                  required
                                  popperClassName="datepicker-zindex"
                                  popperPlacement="bottom-start"
                                  portalId="root-portal"
                                  disabled={
                                    // Type 29 ise veya 28, 30, 54, 1544 ise ve makine veya operasyon yoksa
                                    singleService.type === 29 ||
                                    ([28, 29, 30, 54, 1544].includes(
                                      singleService.type
                                    ) &&
                                      !singleService.isOperationProduct &&
                                      !singleService.isMachineProduct) ||
                                    // Eğer type 1663 değilse ve tanımlı makine & operasyon varsa enable
                                    (singleService.type !== 1663 &&
                                      ![28, 29, 30, 54, 1544].includes(
                                        singleService.type
                                      ))
                                  }
                                />
                              </td>

                              <td className="px-3 py-4">
                                <DatePicker
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[200px] p-2.5"
                                  showTimeSelect
                                  locale={tr}
                                  dateFormat="dd/MM/yyyy - HH:mm"
                                  timeIntervals={15}
                                  selected={singleService.endDate}
                                  onChange={(e) => {
                                    handleEndDate(e, index);
                                  }}
                                  required
                                  popperClassName="datepicker-zindex"
                                  popperPlacement="bottom-start"
                                  portalId="root-portal"
                                  disabled={
                                    // Eğer type 29 ise disable
                                    singleService.type === 29 ||
                                    // 28, 30, 54, 1544 ise ve makine & operasyon yoksa disable
                                    ([28, 29, 30, 54, 1544].includes(
                                      singleService.type
                                    ) &&
                                      !singleService.isOperationProduct &&
                                      !singleService.isMachineProduct) ||
                                    // 28, 30, 54, 1544 ise ve tanımlı makine & operasyon varsa başlangıç enable, bitiş disable
                                    ([28, 29, 30, 54, 1544].includes(
                                      singleService.type
                                    ) &&
                                      singleService.isOperationProduct &&
                                      singleService.isMachineProduct) ||
                                    // Eğer type 1663 değilse bitiş disable
                                    singleService.type !== 1663
                                  }
                                />
                              </td>

                              {singleService.response?.status === -2 &&
                                singleService.response?.success && (
                                  <td className="px-3 py-4">
                                    <Select
                                      options={options}
                                      maxMenuHeight={200}
                                      placeholder={"Strateji belirleyiniz"}
                                      onChange={(e) => {
                                        handleServiceStatusSelectChange(
                                          e,
                                          index,
                                          singleService
                                        );
                                      }}
                                      value={singleService?.status}
                                      menuPortalTarget={document.body} // Menüyü body içinde render eder
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                        control: (base) => ({
                                          ...base,
                                          width: "200px", // Set fixed width
                                        }),
                                      }}
                                      classNamePrefix="custom-select"
                                    />
                                  </td>
                                )}
                              <td className="px-3 py-4">
                                <p
                                  className={`font-semibold text-base ${
                                    (singleService.response?.success &&
                                      singleService.response?.status === 200) ||
                                    singleService.response?.errorMessage ===
                                      "Üretim Planlamaya Uygun"
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }`}
                                >
                                  {singleService.response?.success &&
                                  singleService.response?.status === 200
                                    ? "Üretim planlamaya uygun"
                                    : singleService.response?.errorMessage ||
                                      "-"}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              {singleService.shiftPlanning ? (
                                <td colSpan="8">
                                  <div
                                    style={{
                                      maxWidth: "80vw",
                                      marginBottom: "70px",
                                      margin: "0 auto 70px auto",
                                    }}
                                  >
                                    <div>
                                      <p className="font-semibold text-green-600 text-lg">
                                        Lütfen vardiyaları daha net görebilmek
                                        için başlık alanına{" "}
                                        <span className="text-gray-500">▼</span>{" "}
                                        tıklayınız !
                                      </p>
                                    </div>
                                    <Timeline
                                      groups={[
                                        {
                                          id: singleService.shiftPlanning
                                            ? singleService.shiftPlanning[0]
                                                ?.machineID ||
                                              singleService.shiftPlanning[0]
                                                ?.operationID
                                            : null,
                                          title: singleService.shiftPlanning
                                            ? singleService.shiftPlanning[0]
                                                ?.machineName ||
                                              singleService.shiftPlanning[0]
                                                ?.operationName
                                            : null,
                                        },
                                      ]}
                                      items={
                                        singleService.shiftPlanning
                                          ? singleService.shiftPlanning.map(
                                              (shiftPlan) => ({
                                                id: shiftPlan.shiftID,
                                                group: singleService
                                                  ? shiftPlan.machineID ||
                                                    shiftPlan.operationID
                                                  : shiftPlan.operationID ||
                                                    shiftPlan.machineID,
                                                title: singleService
                                                  ? shiftPlan.workOrderNames ||
                                                    shiftPlan.workOrderNames
                                                  : shiftPlan.workOrderNames ||
                                                    shiftPlan.workOrderNames,
                                                start_time: moment(
                                                  shiftPlan.startDate
                                                ),
                                                end_time: moment(
                                                  shiftPlan.endDate
                                                ),
                                              })
                                            )
                                          : []
                                      }
                                      defaultTimeStart={moment().startOf(
                                        "month"
                                      )}
                                      defaultTimeEnd={moment().endOf("month")}
                                      lineHeight={50}
                                      itemHeightRatio={0.9}
                                      onItemClick={(id) => handleItemClick(id)}
                                    />
                                  </div>
                                </td>
                              ) : null}
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="col-span-4 flex justify-end gap-[20px]">
                  <button
                    type="submit"
                    className={`font-medium text-sm py-2.5 px-12 rounded-lg my-5 text-white h-12 ${
                      allSuccess
                        ? "bg-[#0C692F] hover:bg-[#0c6930e3]"
                        : "bg-[#0c6930e3]  cursor-not-allowed"
                    }`}
                    disabled={!allSuccess}
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default WorkOrderAdd;
