import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const ShippingDetailPdf = ({ logoStr, data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontFamily: "Roboto",
    },
    section: {
      marginBottom: 10,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    invoiceNoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    logo: {
      width: 140, // Adjust width and height to fit your logo
      height: 40,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      marginBottom: 20,
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
    },
    table: {
      marginBottom: 20,
    },
    tableHeader: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      borderBottomStyle: "solid",
      marginBottom: 5,
    },
    tableRow: {
      flexDirection: "row",
      marginBottom: 5,
      borderBottomColor: "gray",
      borderBottomStyle: "solid",
      borderBottomWidth: 0.5,
    },
    tableCell: {
      flex: 1,
      fontSize: 10,
      padding: 5,
    },
    footer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 20,
    },
    logoPlaceholder: {
      fontSize: 20,
      color: "grey",
      fontWeight: "bold",
      textAlign: "center",
    },
  });

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            {logoStr ? (
              <Image src={logoStr} style={styles.logo} />
            ) : (
              <Text style={styles.logoPlaceholder}>LOGO</Text>
            )}
          </View>
          <View style={styles.invoiceNoContainer}>
            <Text style={styles.title}>Sevkiyat: {data?.shippingNo}</Text>
            <Text style={styles.text}>
              Tarih: {formatDate(data?.shippingDate)}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={[styles.text, { fontWeight: "bold" }]}>
            Cari: {data?.account?.name}
          </Text>
          <Text style={styles.text}>Adres: {data?.address}</Text>
          <Text style={styles.text}>Tel No: {data?.account?.telephone}</Text>
          <Text style={styles.text}>E-mail: {data?.account?.email}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableCell}>Ürün Adı</Text>
            <Text style={styles.tableCell}>Miktar</Text>
            <Text style={styles.tableCell}>Barkod</Text>
            <Text style={styles.tableCell}>Net Ağırlık</Text>
            <Text style={styles.tableCell}>Brüt Ağırlık</Text>
            <Text style={styles.tableCell}>Kutu Uzunluğu (cm)</Text>
            <Text style={styles.tableCell}>Kutu Genişliği (cm)</Text>
            <Text style={styles.tableCell}>Kutu Yüksekliği (cm)</Text>
            <Text style={styles.tableCell}>Kutu Hacmi (m³)</Text>
          </View>
          {data?.shippingItems.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{item.name}</Text>
              <Text style={styles.tableCell}>{item.quantity}</Text>
              <Text style={styles.tableCell}>{item.barcode}</Text>
              <Text style={styles.tableCell}>{item.weightPerUnit}</Text>
              <Text style={styles.tableCell}>{item.grossWeight}</Text>
              <Text style={styles.tableCell}>{item.boxLength}</Text>
              <Text style={styles.tableCell}>{item.boxWidth}</Text>
              <Text style={styles.tableCell}>{item.boxHeight}</Text>
              <Text style={styles.tableCell}>{item.boxVolume}</Text>
            </View>
          ))}
        </View>

        <View style={styles.footer}>
          <View style={{ width: "60%" }}>
            <Text style={styles.text}>Açıklama: {data?.description}</Text>
          </View>
  
        </View>
      </Page>
    </Document>
  );
};

export default ShippingDetailPdf;
