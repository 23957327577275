import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import logo from "../../assets/images/logo.png";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import { FaRegFilePdf } from "react-icons/fa6";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { FaCheck } from "react-icons/fa";

/// REACT SPLIDE
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import doc from "../../assets/images/docimg.png";
import { useTranslation } from "react-i18next";
import ShippingDetailPdf from "./ShippingDetailPdf";

const ShippingDetail = () => {
  const { user } = useAuth();
  const params = useParams();
  const { t } = useTranslation();
  const [customerID, setCustomerID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const [logoStr, setLogoStr] = useState(null);
  const [file, setFile] = useState(null);
  const [dataImages, setDataImages] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      setUserID(user.nameidentifier);

      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=1043`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(`/api/Customer/GetCustomerLogo?id=${user.country}`)
            .then((res) => {
              setLogoStr(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Shipping/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setData(res.data);
              setDataImages(res.data.imageFiles);
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Hata",
                text: `${err.response.data}`,
                confirmButtonText: "Tamam",
              }).then((result) => {
                if (result.isConfirmed) {
                  // Yönlendirme işlemi
                  navigate("/dashboards");
                }
              });
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleWorkFlowConfirm = async (wf_confirmID) => {
    Swal.fire({
      title: "Onaylamak istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0C692F",
      cancelButtonColor: "#B10F0F",
      confirmButtonText: "Evet, onayla!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        // Token'ı localStorage'dan veya sessionStorage'dan al
        const token =
          localStorage.getItem("token") || sessionStorage.getItem("token");

        if (!token) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Geçersiz oturum, lütfen tekrar giriş yapın.",
            showConfirmButton: false,
            timer: 1500,
          });
          setLoading(false);
          return;
        }

        try {
          await api
            .put(
              `/api/WorkFlow/PutWorkFlowConfirm/PutWorkFlowConfirm?customerID=${customerID}&wf_confirmID=${wf_confirmID}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`, // Token'ı Authorization başlığına ekleyin
                },
              }
            )
            .then((res) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Onaylandı!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location.reload();
              });
            })
            .catch((error) => {
              const errorMessageKey = error.response?.data;
              const translatedError = errorMessageKey
                ? t(`errors.${errorMessageKey}`, {
                    defaultValue: errorMessageKey,
                  })
                : t("errors.UnknownError", {
                    defaultValue: "Bilinmeyen bir hata",
                  });
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: `${translatedError}`,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "İşlem başarısız oldu!",
            showConfirmButton: false,
            timer: 1500,
          });
          setLoading(false);
        }
      }
    });
  };

  // FILE ONCHANGE
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const handleDocSubmit = async (e) => {
    setLoading(true);

    const formData = new FormData();
    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Dosyaları yükle (eğer varsa)
      if (file && file.length > 0) {
        await api.post(
          `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=4854&relationID=${params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Dosyalar yüklendi!",
        showConfirmButton: false,
        timer: 1500,
      });

      // SweetAlert tamamlandıktan sonra sayfa yenile
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });

      // Hata durumunda da SweetAlert'ten sonra sayfa yenile
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />

      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Sevkiyat", href: "/shipping" },
                { label: data?.title || "Sevkiyat Detayı", href: "" },
              ]}
            />
            <div className="demandDetailCont mx-auto my-12">
              {permissions.showPricePermission && (
                <div className="absolute right-0 pr-5">
                  <PDFDownloadLink
                    document={
                      <ShippingDetailPdf logoStr={logoStr} data={data} />
                    }
                    fileName={`${data.shippingNo || "FORM"}.pdf`}
                  >
                    {({ loading }) =>
                      loading ? (
                        <a className="text-lg font-medium text-red-600 hover:underline">
                          Yükleniyor...
                        </a>
                      ) : (
                        <a className="text-lg font-medium text-red-600 hover:underline flex gap-2">
                          <FaRegFilePdf size={30} />
                          Çıktı al.
                        </a>
                      )
                    }
                  </PDFDownloadLink>
                </div>
              )}

              <div className="demandPage min-h-[900px]">
                <div className="page-top">
                  <div className="page-top-logo">
                    <img className="w-[200px] mb-3" src={logoStr} alt="" />
                    <p>Adres: {data?.account?.address}</p>
                  </div>
                  <div className="page-top-info">
                    <h1>Sevkiyat </h1>
                    <h1>{data?.shippingNo}</h1>
                    <p>
                      <span>Tarih :</span>{" "}
                      {data.shippingDate
                        ? formatDate(data?.shippingDate)
                        : null}
                    </p>
                  </div>
                </div>
                <div className="page-mid-info">
                  <p>
                    Cari:{" "}
                    <span
                      style={{ fontWeight: "600", color: "rgb(63, 63, 63)" }}
                    >
                      {data?.account?.name}
                    </span>
                  </p>
                  <p>Tel No: {data?.account?.telephone}</p>
                  <p>E-mail: {data?.account?.email}</p>
                </div>

                <div className="page-mid-items relative overflow-x-auto my-8">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-3 py-3">
                          Ürün Adı
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Miktar
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Barkod
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Net Ağırlık
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Brüt Ağırlık
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Kutu Uzunluğu (cm)
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Kutu Genişliği (cm)
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Kutu Yüksekliği (cm)
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Kutu Hacmi (m³)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.shippingItems?.map((item, index) => (
                        <tr key={index} className="bg-white border-b">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {item.name}
                          </th>
                          <td className="px-6 py-4">{item.quantity}</td>
                          <td className="px-6 py-4">{item.barcode}</td>
                          <td className="px-6 py-4">{item.weightPerUnit}</td>
                          <td className="px-6 py-4">{item.grossWeight}</td>
                          <td className="px-6 py-4">{item.boxLength}</td>
                          <td className="px-6 py-4">{item.boxWidth}</td>
                          <td className="px-6 py-4">{item.boxHeight}</td>
                          <td className="px-6 py-4">{item.boxVolume}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="page-users mt-10 py-4 border-gray-300">
                  <h2 className="text-lg font-semibold mb-4">Onaylar:</h2>
                  <table className="w-full border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-[#050A27]">
                        <th className="border border-gray-300 px-4 py-2 text-left text-white">
                          Onay Tipi
                        </th>
                        <th className="border border-gray-300 px-4 py-2 text-left text-white">
                          Onaylayan
                        </th>
                        <th className="border border-gray-300 px-4 py-2 text-left text-white">
                          Onay Tarihi
                        </th>
                        <th className="border border-gray-300 px-4 py-2 text-center text-white">
                          İşlemler
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.workFlow_Confirms?.map((confirm, index) => (
                        <tr
                          key={confirm.wF_ConfirmID}
                          className={
                            index % 2 === 0 ? "bg-gray-100" : "bg-white"
                          }
                        >
                          <td className="border border-gray-300 px-4 py-2 break-words whitespace-normal">
                            {confirm.workFlowName}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 break-words whitespace-normal">
                            {confirm.userName}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 break-words whitespace-normal">
                            {confirm.confirmDate
                              ? formatDate(confirm.confirmDate)
                              : null}
                          </td>
                          {!confirm.isConfirm &&
                            Number(confirm?.userID) === Number(userID) && (
                              <td className="border border-gray-300 px-4 py-2 break-words whitespace-normal text-center">
                                <div className="flex justify-center items-center">
                                  <button
                                    type="button"
                                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center mb-2"
                                    title={confirm.workFlowName}
                                    onClick={() => {
                                      handleWorkFlowConfirm(
                                        confirm.wF_ConfirmID
                                      );
                                    }}
                                  >
                                    {confirm.workFlowName}
                                    <i className="fa-solid fa-check ml-2"></i>
                                  </button>
                                </div>
                              </td>
                            )}

                          {confirm.isConfirm && (
                            <td className="border border-gray-300 px-4 py-5 text-center flex justify-center items-center">
                              <a className="font-medium text-[#0C692F] hover:underline">
                                <FaCheck
                                  className="hover:scale-110 transition-transform duration-200"
                                  size={24}
                                />
                              </a>
                            </td>
                          )}
                          {!confirm.isConfirm &&
                            Number(confirm?.userID) !== Number(userID) && (
                              <td className="border border-gray-300 px-4 py-5 text-center flex justify-center items-center">
                                <a className="font-medium text-yellow-600 hover:underline">
                                  Bekliyor
                                </a>
                              </td>
                            )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-full">
                <Splide
                  options={{
                    autoWidth: true,
                    gap: 100,
                    pagination: false,
                    padding: 100,
                  }}
                  aria-label="My Favorite Images"
                >
                  {dataImages?.map((item, index) => {
                    const imageName = item.split("/").pop();
                    const altText = imageName.split("_").pop().split(".")[0];
                    // Resim URL'si kontrolü
                    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(item);
                    return (
                      <SplideSlide key={index}>
                        {isImage ? (
                          <Link to={item}>
                            <img
                              className="max-w-[200px] cursor-pointer"
                              src={item}
                              alt={`Image ${index + 1}`}
                            />
                          </Link>
                        ) : (
                          <Link to={item}>
                            <img
                              className="max-w-[100px] cursor-pointer"
                              src={`${doc}`}
                            />
                          </Link>
                        )}
                        <p className="text-center font-medium">
                          {truncateText(altText, 10)}
                        </p>
                      </SplideSlide>
                    );
                  })}
                </Splide>
              </div>
              <div className="flex items-center w-full mt-12">
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                  id="multiple_files"
                  type="file"
                  multiple
                  onChange={handleChangeFile}
                />
                <button
                  type="button"
                  className="bg-gray-900  hover:bg-gray-700  text-white font-bold py-2 px-4 rounded-xl"
                  onClick={() => handleDocSubmit()}
                >
                  Yükle
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ShippingDetail;
