import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api from "../../api/api";
import { AiOutlinePlus } from "react-icons/ai/";
import SideBarRight from "../../components/modal/SideBarRight";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";
/// REACT SPLIDE
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import doc from "../../assets/images/docimg.png";

const ShippingEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [data, setData] = useState({});
  const [shippingItems, setShippingItems] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [stockCards, setStockCards] = useState([]);
  const [stockCardsOpt, setStockCardsOpt] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountsOpt, setAccountsOpt] = useState([]);
  const [title, setTitle] = useState("");
  const [shippingName, setShippingName] = useState("");
  const [openSideModal, setOpenSideModal] = useState(false);
  const [token, setToken] = useState(null);
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [file, setFile] = useState(null);
  const [dataImages, setDataImages] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=1043`
        )
        .then((res) => {
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboards");
              }
            });
            return;
          }

          api
            .get(
              `/api/Account/GetAccounts?customerID=${user.country}&typeID=26&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setAccounts(res.data.items);
              const newDataAccounts = res.data?.items?.map((item) => ({
                value: item.accountID,
                label: item.name,
              }));

              setAccountsOpt(newDataAccounts);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/StockCard/GetStockCardsByShippingWareHouse?customerID=${user.country}`
            )
            .then((res) => {
              setStockCards(res.data);
              const newDataStockCards = res.data?.map((item) => ({
                value: item.stockCardID,
                label: `${item.code} - ${item.name}`,
                type: item.type,
              }));

              setStockCardsOpt(newDataStockCards);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Shipping/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setTitle(res.data.title);
              setShippingName(res.data.name);
              setDataImages(res.data.imageFiles);
              setSelectedAccount({
                value: res.data.account.accountID,
                label: res.data.account.name,
              });
              setShippingItems(res.data.shippingItems);
              setData(res.data);
              setStartDate(new Date(res.data.shippingDate));
              setDescription(res.data.description);
              const data = JSON.parse(res.data.keywords);
              const newData = data.map((item) => ({
                value: item,
                label: item,
                __isNew__: true,
              }));
              setKeywords(newData);
            })

            .catch((err) => {
              console.log(err.data);
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  //ADD ITEMS///

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...shippingItems];
    list[index][name] = value;

    setShippingItems(list);
  };

  const handleSelectChange = (e, index) => {
    const list = [...shippingItems];
    list[index].name = e.label;
    list[index].type = e.type;
    list[index].stockCardID = e.value;
    const stockCardsValue = stockCards.find(
      (item) => item.stockCardID === e.value
    );
    list[index].barcode = stockCardsValue.barcode;
    list[index].weightPerUnit = stockCardsValue.weightPerUnit;
    list[index].grossWeight = stockCardsValue.grossWeight;
    list[index].boxLength = stockCardsValue.boxLength;
    list[index].boxWidth = stockCardsValue.boxWidth;
    list[index].boxHeight = stockCardsValue.boxHeight;
    list[index].boxVolume = stockCardsValue.boxVolume;
    setShippingItems(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...shippingItems];
    list.splice(index, 1);
    setShippingItems(list);
  };

  const handleServiceAdd = () => {
    setShippingItems([
      ...shippingItems,
      {
        customerID,
        shippingID: params.id,
        stockCardID: Number(),
        name: "",
        quantity: Number(),
        barcode: Number(),
        weightPerUnit: Number(),
        grossWeight: Number(),
        boxLength: Number(),
        boxWidth: Number(),
        boxHeight: Number(),
        boxVolume: Number(),
        type: Number(),
        description: "",
      },
    ]);
  };

  //////////////////////////

  const find = accounts.find(
    (item) => item.accountID === selectedAccount.value
  );

  const postData = {
    customerID,
    shippingID: params.id,
    accountID: selectedAccount.value,
    name: shippingName,
    title: title,
    address: find?.address,
    shippingDate: startDate,
    status: 0,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    shippingItems,
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .put("/api/Shipping/Put/Put", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Sevkiyat güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/shipping");
      })

      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire(translatedError);
      })
      .finally(() => setLoading(false));
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // FILE ONCHANGE
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const handleDocSubmit = async (e) => {
    setLoading(true);

    const formData = new FormData();
    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Dosyaları yükle (eğer varsa)
      if (file && file.length > 0) {
        await api.post(
          `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=4854&relationID=${params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Dosyalar yüklendi!",
        showConfirmButton: false,
        timer: 1500,
      });

      // SweetAlert tamamlandıktan sonra sayfa yenile
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });

      // Hata durumunda da SweetAlert'ten sonra sayfa yenile
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } finally {
      setLoading(false);
    }
  };
  console.log(data);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Sevkiyat", href: "/shipping" },
                { label: "Sevkiyat Güncelle", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen flex justify-between w-[90%] bg-gray-100">
              <form onSubmit={handleSubmit} className="w-full">
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">
                    Sevkiyat Güncelle
                  </h1>
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="title"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlık
                    </label>
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      id="title"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="shippingName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adı
                    </label>
                    <input
                      value={shippingName}
                      onChange={(e) => setShippingName(e.target.value)}
                      type="text"
                      id="shippingName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="account"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Cari
                    </label>
                    <Select
                      maxMenuHeight={200}
                      id="account"
                      onChange={(e) => setSelectedAccount(e)}
                      value={selectedAccount}
                      options={accountsOpt}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="address"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Adres
                    </label>
                    <input
                      value={find?.address}
                      readOnly
                      type="text"
                      id="address"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler
                    </label>
                    <CreatableSelect
                      onChange={(e) => setKeywords(e)}
                      options={customerKey}
                      isMulti
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama
                    </label>
                    <input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      id="description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="date"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Tarih
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                      placeholder="Seçiniz..."
                    />
                  </div>
                  <div className="flex items-center col-span-2">
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                      id="multiple_files"
                      type="file"
                      multiple
                      onChange={handleChangeFile}
                    />
                    <button
                      type="button"
                      className="bg-gray-900  hover:bg-gray-700  text-white font-bold py-2 px-4 rounded-xl"
                      onClick={() => handleDocSubmit()}
                    >
                      Yükle
                    </button>
                  </div>
                  <div className="col-span-2 my-12">
                    <Splide
                      options={{
                        autoWidth: true,
                        gap: 100,
                        pagination: false,
                        padding: 100,
                      }}
                      aria-label="My Favorite Images"
                    >
                      {dataImages?.map((item, index) => {
                        const imageName = item.split("/").pop();
                        const altText = imageName
                          .split("_")
                          .pop()
                          .split(".")[0];
                        // Resim URL'si kontrolü
                        const isImage = /\.(jpg|jpeg|png|gif)$/i.test(item);
                        return (
                          <SplideSlide key={index}>
                            {isImage ? (
                              <Link to={item}>
                                <img
                                  className="max-w-[200px] cursor-pointer"
                                  src={item}
                                  alt={`Image ${index + 1}`}
                                />
                              </Link>
                            ) : (
                              <Link to={item}>
                                <img
                                  className="max-w-[100px] cursor-pointer"
                                  src={`${doc}`}
                                />
                              </Link>
                            )}
                            <p className="text-center font-medium">
                              {truncateText(altText, 10)}
                            </p>
                          </SplideSlide>
                        );
                      })}
                    </Splide>
                  </div>
                </div>

                <div className="relative overflow-x-auto mb-5">
                  <table className="w-full text-sm text-left text-gray-500 overflow-x-auto">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-3 py-3">
                          Ürün Adı
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Miktar
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Barkod
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Net Ağırlık
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Brüt Ağırlık
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Kutu Uzunluğu (cm)
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Kutu Genişliği (cm)
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Kutu Yüksekliği (cm)
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Kutu Hacmi (m³)
                        </th>
                        <th scope="col" className="px-3 py-3">
                          İşlem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {shippingItems.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-3 py-4">
                            <div className="min-w-[180px]">
                              <Select
                                maxMenuHeight={200}
                                onChange={(e) => handleSelectChange(e, index)}
                                options={stockCardsOpt}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..."
                                value={{
                                  value: singleService.stockCardID,
                                  label: singleService.name,
                                }}
                              />
                            </div>
                          </td>

                          <td className="px-3 py-4">
                            <input
                              value={singleService.quantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="quantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 min-w-[70px]"
                            />
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.barcode}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="barcode"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 min-w-[70px]"
                            />
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.weightPerUnit}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="weightPerUnit"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            />
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.grossWeight}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="grossWeight"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            />
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.boxLength}
                              name="boxLength"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              onChange={(e) => handleServiceChange(e, index)}
                            />
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.boxWidth}
                              name="boxWidth"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              onChange={(e) => handleServiceChange(e, index)}
                            />
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.boxHeight}
                              name="boxHeight"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              onChange={(e) => handleServiceChange(e, index)}
                            />
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.boxVolume}
                              name="boxVolume"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              onChange={(e) => handleServiceChange(e, index)}
                            />
                          </td>

                          <td className="px-3 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-2 px-4 rounded-lg m-5"
                              title="Sil"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <button
                      onClick={handleServiceAdd}
                      type="button"
                      className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg my-5 h-10 w-24"
                      title="Ekle"
                    >
                      Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </table>
                </div>

                <div>
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    title="Kaydet"
                  >
                    Kaydet <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
              <div>
                <button className="absolute top-36 right-0 bg-[#050A27] rounded-l-lg">
                  <AiOutlinePlus
                    size={40}
                    color="white"
                    onClick={() => setOpenSideModal(true)}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        {openSideModal === true && (
          <SideBarRight onCancel={() => setOpenSideModal(false)} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default ShippingEdit;
