import React, { useEffect, useState } from "react";
import Header from "../../../components/bars/Header";
import Sidebars from "../../../components/bars/Sidebar";
import "../../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../api/api";
import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { useAuth } from "../../../context/AuthContext";
import * as XLSX from "xlsx";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa6";
import Footer from "../../../components/bars/Footer";
import { LuSend } from "react-icons/lu";
import OfferPdfModal from "../OfferPdfModal";
import OfferPdfDetailModal from "../OfferPdfDetailModal";
import LoadingSpinner from "../../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";

const PurchaseOffers = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [openPdfDetailModal, setOpenPdfDetailModal] = useState(false);
  const [dataItem, setDataItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını depolamak için
  const [pageSize, setPageSize] = useState(20);
  const [openDetailIds, setOpenDetailIds] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    name: "desc",
    title: "desc",
    districtName: "desc",
    address: "desc",
    taxOffice: "desc",
    taxNumber: "desc",
    offerNo: "desc",
    offerDate: "desc",
    expiryDate: "desc",
    totalPrice: "desc",
  });

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=44`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Offer/GetPurchaseOffers?customerID=${user.country}&pageNumber=${currentPage}&pageSize=${pageSize}`
            )
            .then((res) => {
              setData(res.data.items);
              res.data.items.offerDate = formatDate(res.data.items.offerDate);
              res.data.items.expiryDate = formatDate(res.data.items.expiryDate);
              setTotalPages(res.data.totalPages);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, currentPage, pageSize]);

  ///////////////////////// PAGING
  const nextPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)); // Son sayfayı aşmamak için kontrol
  };

  const prevPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5 && currentPage > 3) {
      startPage = currentPage - 2;
      endPage = Math.min(currentPage + 2, totalPages);
    } else {
      endPage = Math.min(totalPages, 5);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`flex items-center justify-center px-4 h-10 leading-tight ${
              currentPage === i
                ? "text-blue-600 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }`}
            onClick={() => goToPage(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
  };

  const handleSort = (columnName) => {
    const sortOrderCopy = { ...sortOrder };
    sortOrderCopy[columnName] =
      sortOrder[columnName] === "asc" ? "desc" : "asc";
    setSortOrder(sortOrderCopy);

    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (
          columnName === "name" ||
          columnName === "title" ||
          columnName === "address" ||
          columnName === "taxOffice" ||
          columnName === "taxNumber" ||
          columnName === "offerNo" ||
          columnName === "offerDate" ||
          columnName === "expiryDate" ||
          columnName === "totalPrice"
        ) {
          if (sortOrderCopy[columnName] === "asc") {
            return a[columnName]?.localeCompare(b[columnName]);
          } else {
            return b[columnName]?.localeCompare(a[columnName]);
          }
        } else {
          // Sayısal sütunlar için sıralama işlevini çağır
          return numericSort(
            a[columnName],
            b[columnName],
            sortOrderCopy[columnName]
          );
        }
      });
      return sortedData;
    });
  };

  const numericSort = (a, b, sortOrder) => {
    if (sortOrder === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };
  /////////////////////////////////////////////

  const handleDelete = async (offerID, customerID) => {
    try {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Offer/DeleteOffer/${offerID}?customerID=${customerID}`
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.offerID !== offerID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Teklif silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Teklif silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  const postOfferToOrder = async (offerID, customerID) => {
    Swal.fire({
      title: "Siparişe göndermek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet, gönder!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          await api
            .post(
              `/api/Order/PostOfferToOrder/PostOfferToOrder?offerID=${offerID}&customerID=${customerID}`
            )
            .then((res) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Siparişe gönderildi!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location.reload();
              });
            })
            .catch((error) => {
              const errorMessageKey = error.response?.data;
              const translatedError = errorMessageKey
                ? t(`errors.${errorMessageKey}`, {
                    defaultValue: errorMessageKey,
                  })
                : t("errors.UnknownError", {
                    defaultValue: "Bilinmeyen bir hata",
                  });
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: `${translatedError}`,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "İşlem başarısız oldu!",
            showConfirmButton: false,
            timer: 1500,
          });
          setLoading(false);
        }
      }
    });
  };

  const postControlAprrovalOffer = async (offerID, customerID) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token"); // token'ı localStorage veya sessionStorage'dan alıyoruz
    if (!token) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Token bulunamadı, lütfen tekrar giriş yapın.",
        showConfirmButton: false,
        timer: 1500,
      });
      return; // Token yoksa işlem yapılmasın
    }

    Swal.fire({
      title: "Kontrol onayı vermek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet, edilsin!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const res = await api.put(
            `/api/Offer/PutOfferStatus/PutOfferStatus?customerID=${customerID}&offerID=${offerID}&status=3788`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`, // Authorization başlığını ekliyoruz
              },
            }
          );
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Kontrol onayı verildi",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          const errorMessageKey = error.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${translatedError}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };
  const postConfirmOffer = async (offerID, customerID) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token"); // token'ı localStorage veya sessionStorage'dan alıyoruz
    if (!token) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Token bulunamadı, lütfen tekrar giriş yapın.",
        showConfirmButton: false,
        timer: 1500,
      });
      return; // Token yoksa işlem yapılmasın
    }

    Swal.fire({
      title: "Teklifi onaylamak istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet, onayla!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const res = await api.put(
            `/api/Offer/PutOfferStatus/PutOfferStatus?customerID=${customerID}&offerID=${offerID}&status=3789`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`, // Authorization başlığını ekliyoruz
              },
            }
          );
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Teklif onaylandı!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          const errorMessageKey = error.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${translatedError}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  // ITEMS TO EXCEL
  const handleExportData = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token");
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPdfData(response.data);

      const worksheet = XLSX.utils.json_to_sheet(data);

      // Örneğin, A, B, C sütunları için genişlik ayarı
      worksheet["!cols"] = [
        { wch: 10 }, // A sütunu genişliği
        { wch: 10 }, // B sütunu genişliği
        { wch: 10 }, // C sütunu genişliği
        { wch: 10 }, // D sütunu genişliği
        { wch: 10 }, // E sütunu genişliği
        { wch: 20 }, // F sütunu genişliği
        { wch: 20 }, // G sütunu genişliği
        { wch: 100 }, // H sütunu genişliği
        { wch: 60 }, // I sütunu genişliği
        { wch: 10 }, // J sütunu genişliği
        { wch: 10 }, // K sütunu genişliği
        { wch: 20 }, // L sütunu genişliği
        { wch: 20 }, // M sütunu genişliği
        { wch: 20 }, // N sütunu genişliği
        { wch: 20 }, // O sütunu genişliği
        { wch: 10 }, // P sütunu genişliği
        { wch: 10 }, // Q sütunu genişliği
        { wch: 10 }, // R sütunu genişliği
        { wch: 10 }, // S sütunu genişliği
        { wch: 20 }, // T sütunu genişliği
        { wch: 10 }, // U sütunu genişliği
        { wch: 10 }, // V sütunu genişliği
        { wch: 10 }, // W sütunu genişliği
        { wch: 100 }, // X sütunu genişliği
        { wch: 50 }, // Y sütunu genişliği
        { wch: 10 }, // Z sütunu genişliği
        { wch: 100 }, // AA sütunu genişliği
        { wch: 50 }, // AB sütunu genişliği
        { wch: 10 }, // AC sütunu genişliği
        { wch: 20 }, // AD sütunu genişliği
        { wch: 20 }, // AE sütunu genişliği
        { wch: 40 }, // AF sütunu genişliği
        { wch: 10 }, // AG sütunu genişliği
        // ve böyle devam eder...
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const fileName = `${response.data}.xlsx`; // Dosya adını ve uzantısını belirtiyoruz
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
    }
  };
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const fetchDocumentNumber = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token"); // Token'ı localStorage'dan alabilirsiniz
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header'ını ekliyoruz
          },
        }
      );

      // Mevcut veri ile API'den gelen veriyi birleştiriyoruz

      setPdfData(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfModal(true); // Modal'ı açıyoruz
    }
  };

  const fetchSingleItem = async (id) => {
    setLoading(true);

    try {
      const response = await api.get(
        `/api/Offer/Get?id=${id}&customerID=${customerID}`
      );
      setDataItem(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfDetailModal(true); // Modal'ı açıyoruz
    }
  };

  // Detay açma işlevi
  const openDetail = (offerID) => {
    setOpenDetailIds((prevIds) => [...prevIds, offerID]);
  };

  // Detay kapatma işlevi
  const closeDetail = (offerID) => {
    setOpenDetailIds((prevIds) => prevIds.filter((id) => id !== offerID));
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 3787:
        return { label: "Yeni", color: "bg-gray-500 text-gray-100" };
      case 3788:
        return {
          label: "Konrol Edildi",
          color: "bg-yellow-700 text-yellow-100",
        };
      case 3789:
        return { label: "Onaylandı", color: "bg-green-700 text-green-100" };
      case 3790:
        return { label: "Reddedildi", color: "bg-red-700 text-red-100" };
      case 3792:
        return { label: "İptal oldu", color: "bg-gray-700 text-gray-100" };
      default:
        return { label: "Tanımsız", color: "bg-gray-700 text-gray-100" };
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <>
          <div className="add-button pt-6">
            {permissions.insertPermission && (
              <Link to={"/purchaseOfferAdd"}>
                <button
                  type="button"
                  className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-[19rem]"
                  title="Teklif oluştur"
                >
                  Satın Alma Teklifi Oluştur
                  <i className="fa-solid fa-plus ml-2"></i>
                </button>
              </Link>
            )}
            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title ">
                Satın Alma Teklifleri
              </h1>
            </div>
            <div className="flex justify-between pt-2">
              <input
                className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                type="text"
                placeholder="Teklif ara.."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              {permissions.showPricePermission && (
                <div className="flex gap-5 mr-4">
                  <a
                    onClick={fetchDocumentNumber}
                    className="font-medium text-[#050A27] hover:underline cursor-pointer"
                    title="PDF çıktı al"
                  >
                    <FaRegFilePdf
                      className="hover:scale-110 transition-transform duration-200"
                      size={30}
                    />
                  </a>
                  <button onClick={handleExportData}>
                    <a
                      title="Excel çıktı al"
                      className="font-medium text-[#050A27] hover:underline cursor-pointer"
                    >
                      <RiFileExcel2Line
                        className="hover:scale-110 transition-transform duration-200"
                        size={35}
                      />
                    </a>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                <tr>
                  <th scope="col" className="px-3 py-3 "></th>
                  <th
                    scope="col"
                    className="px-3 py-3 cursor-pointer"
                    onClick={() => handleSort("name")}
                  >
                    Teklif Adı {sortOrder.name === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 cursor-pointer"
                    onClick={() => handleSort("title")}
                  >
                    Başlık {sortOrder.title === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 cursor-pointer"
                    onClick={() => handleSort("address")}
                  >
                    Adres {sortOrder.address === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 cursor-pointer"
                    onClick={() => handleSort("taxOffice")}
                  >
                    Vergi Dairesi {sortOrder.taxOffice === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 cursor-pointer"
                    onClick={() => handleSort("taxNumber")}
                  >
                    Vergi Numarası {sortOrder.taxNumber === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 cursor-pointer"
                    onClick={() => handleSort("offerNo")}
                  >
                    Teklif Numarası {sortOrder.offerNo === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 cursor-pointer"
                    onClick={() => handleSort("offerDate")}
                  >
                    Tarih {sortOrder.offerDate === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 cursor-pointer"
                    onClick={() => handleSort("expiryDate")}
                  >
                    Son Tarih {sortOrder.expiryDate === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 cursor-pointer"
                    onClick={() => handleSort("totalPrice")}
                  >
                    Toplam Fiyat {sortOrder.totalPrice === "asc" ? "▲" : "▼"}
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Durum
                  </th>
                  <th scope="col" className="px-3 py-3 text-center">
                    Rev.
                  </th>
                  <th scope="col" className="px-3 py-3 cursor-pointer">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data
                    ?.filter(
                      (item) =>
                        item.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.title
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.address
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.taxOffice
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.taxNumber
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.offerNo
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.offerDate
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.expiryDate
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.totalPrice
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                    )
                    .map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr
                            key={index}
                            className={`bg-white border-b hover:bg-gray-50 ${
                              item.revisions.length > 0 ? "cursor-pointer" : ""
                            }`}
                            onClick={() =>
                              openDetailIds.includes(item.offerID)
                                ? closeDetail(item.offerID)
                                : openDetail(item.offerID)
                            }
                          >
                            {item.revisions.length > 0 ? (
                              <td className="text-center px-3 py-4">
                                <FaAngleDown
                                  className={`transform transition-transform ${
                                    openDetailIds.includes(item.offerID)
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                  size={18}
                                  color="gray"
                                />
                              </td>
                            ) : (
                              <td className="px-3 py-4"></td>
                            )}
                            <th
                              scope="row"
                              className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {truncateText(item.name, 30)}
                            </th>
                            <td title={item.title} className="px-3 py-4">
                              {truncateText(item.title, 30)}
                            </td>
                            <td title={item.address} className="px-3 py-4">
                              {truncateText(item.address, 30)}
                            </td>
                            <td className="px-3 py-4">{item.taxOffice}</td>
                            <td className="px-3 py-4">{item.taxNumber}</td>
                            <td className="px-3 py-4">{item.offerNo}</td>
                            <td className="px-3 py-4">
                              {formatDate(item.offerDate)}
                            </td>
                            <td className="px-3 py-4">
                              {formatDate(item.expiryDate)}
                            </td>
                            {permissions.showPricePermission ? (
                              <td className="px-3 py-4">{item.totalPrice}</td>
                            ) : (
                              <td className="px-3 py-4"></td>
                            )}
                            <td className="px-3 py-4">
                              <span
                                title={
                                  item.cancelledForRejection ||
                                  item.reasonForRejection
                                }
                                className={`inline-block rounded px-2 py-1 text-xs font-semibold ${
                                  getStatusLabel(item.status).color
                                }`}
                              >
                                {getStatusLabel(item.status).label}
                              </span>
                            </td>
                            <td className="px-3 py-4 text-center font-semibold text-base">
                              {item.revisionCount}
                            </td>
                            <td className="px-3 py-4">
                              <div className="flex items-center space-x-3">
                                {item.status === 3788 &&
                                  item.approvelRequest && (
                                    <button
                                      onClick={() => {
                                        postConfirmOffer(
                                          item.offerID,
                                          item.customerID
                                        );
                                      }}
                                    >
                                      <a
                                        title="Teklifi onayla"
                                        className="font-medium text-[#0C692F] hover:underline cursor-pointer"
                                      >
                                        <FaCheck
                                          className="hover:scale-110 transition-transform duration-200"
                                          size={24}
                                        />
                                      </a>
                                    </button>
                                  )}
                                {item.status === 3787 &&
                                  item.controlRequest && (
                                    <button
                                      onClick={() => {
                                        postControlAprrovalOffer(
                                          item.offerID,
                                          item.customerID
                                        );
                                      }}
                                    >
                                      <a
                                        title="Kontrol onayı ver"
                                        className="font-medium text-[#050A27] hover:underline cursor-pointer"
                                      >
                                        <FaCheck
                                          className="hover:scale-110 transition-transform duration-200"
                                          size={24}
                                        />
                                      </a>
                                    </button>
                                  )}

                                {item.status === 3789 && (
                                  <button
                                    onClick={() => {
                                      postOfferToOrder(
                                        item.offerID,
                                        item.customerID
                                      );
                                    }}
                                  >
                                    <a
                                      title="Siparişe gönder"
                                      className="font-medium text-[#050A27] hover:underline cursor-pointer"
                                    >
                                      <LuSend
                                        className="hover:scale-110 transition-transform duration-200"
                                        size={24}
                                      />
                                    </a>
                                  </button>
                                )}
                                {permissions.showPricePermission && (
                                  <a
                                    onClick={() =>
                                      fetchSingleItem(item.offerID)
                                    }
                                    className="font-medium text-[#050A27] hover:underline cursor-pointer"
                                    title="PDF çıktı al"
                                  >
                                    <FaRegFilePdf
                                      className="hover:scale-110 transition-transform duration-200"
                                      size={24}
                                    />
                                  </a>
                                )}
                                {permissions.reportPermission && (
                                  <Link to={`/offerDetail/${item.offerID}`}>
                                    <a
                                      title="Teklif detay"
                                      className="font-medium text-[#050A27] hover:underline"
                                    >
                                      <FaRegEye
                                        className="hover:scale-110 transition-transform duration-200"
                                        size={24}
                                      />
                                    </a>
                                  </Link>
                                )}
                                {permissions.updatePermission && (
                                  <Link
                                    to={`/purchaseOfferEdit/${item.offerID}`}
                                  >
                                    <a
                                      title="Teklif düzenle"
                                      className="font-medium text-[#050A27]  hover:underline"
                                    >
                                      <TbEdit
                                        className="hover:scale-110 transition-transform duration-200"
                                        size={24}
                                      />
                                    </a>
                                  </Link>
                                )}
                                {permissions.deletePermission && (
                                  <button
                                    onClick={() => {
                                      handleDelete(
                                        item.offerID,
                                        item.customerID
                                      );
                                    }}
                                  >
                                    <a
                                      title="Teklif sil"
                                      className="font-medium text-[#050A27]  hover:underline"
                                    >
                                      <RiDeleteBin6Line
                                        className="hover:scale-110 transition-transform duration-200"
                                        size={24}
                                      />
                                    </a>
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                          {openDetailIds.includes(item.offerID) && // Detay açık ise alt satırları göster
                            item.revisions.map((detail, idx) => (
                              <tr
                                key={idx}
                                className={`border-b hover:bg-gray-200 bg-gray-100`}
                              >
                                <td className="px-3 py-4"></td>

                                <th
                                  scope="row"
                                  className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {truncateText(detail.name, 30)}
                                </th>
                                <td title={detail.title} className="px-3 py-4">
                                  {truncateText(detail.title, 30)}
                                </td>
                                <td
                                  title={detail.address}
                                  className="px-3 py-4"
                                >
                                  {truncateText(detail.address, 30)}
                                </td>
                                <td className="px-3 py-4">
                                  {detail.taxOffice}
                                </td>
                                <td className="px-3 py-4">
                                  {detail.taxNumber}
                                </td>
                                <td className="px-3 py-4">{detail.offerNo}</td>
                                <td className="px-3 py-4">
                                  {formatDate(detail.offerDate)}
                                </td>
                                <td className="px-3 py-4">
                                  {formatDate(detail.expiryDate)}
                                </td>
                                {permissions.showPricePermission ? (
                                  <td className="px-3 py-4">
                                    {detail.totalPrice}
                                  </td>
                                ) : (
                                  <td className="px-3 py-4"></td>
                                )}
                                <td className="px-3 py-4">
                                  <span
                                    title={
                                      detail.cancelledForRejection ||
                                      detail.reasonForRejection
                                    }
                                    className={`inline-block rounded px-2 py-1 text-xs font-semibold ${
                                      getStatusLabel(detail.status).color
                                    }`}
                                  >
                                    {getStatusLabel(detail.status).label}
                                  </span>
                                </td>
                                <td className="px-3 py-4"></td>

                                <td className="px-3 py-4">
                                  <div className="flex items-center space-x-3">
                                    {!detail.offerID && (
                                      <button
                                        onClick={() => {
                                          postOfferToOrder(
                                            detail.offerID,
                                            detail.customerID
                                          );
                                        }}
                                      >
                                        <a
                                          title="Siparişe gönder"
                                          className="font-medium text-[#050A27] hover:underline cursor-pointer"
                                        >
                                          <LuSend
                                            className="hover:scale-110 transition-transform duration-200"
                                            size={24}
                                          />
                                        </a>
                                      </button>
                                    )}
                                    {permissions.showPricePermission && (
                                      <a
                                        onClick={() =>
                                          fetchSingleItem(detail.offerID)
                                        }
                                        className="font-medium text-[#050A27] hover:underline cursor-pointer"
                                        title="PDF çıktı al"
                                      >
                                        <FaRegFilePdf
                                          className="hover:scale-110 transition-transform duration-200"
                                          size={24}
                                        />
                                      </a>
                                    )}
                                    {permissions.reportPermission && (
                                      <Link
                                        to={`/offerDetail/${detail.offerID}`}
                                      >
                                        <a
                                          title="Teklif detay"
                                          className="font-medium text-[#050A27] hover:underline"
                                        >
                                          <FaRegEye
                                            className="hover:scale-110 transition-transform duration-200"
                                            size={24}
                                          />
                                        </a>
                                      </Link>
                                    )}
                                    {permissions.deletePermission && (
                                      <button
                                        onClick={() => {
                                          handleDelete(
                                            detail.offerID,
                                            detail.customerID
                                          );
                                        }}
                                      >
                                        <a
                                          title="Teklif sil"
                                          className="font-medium text-[#050A27]  hover:underline"
                                        >
                                          <RiDeleteBin6Line
                                            className="hover:scale-110 transition-transform duration-200"
                                            size={24}
                                          />
                                        </a>
                                      </button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </React.Fragment>
                      );
                    })
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="px-6 py-4 text-center text-2xl font-medium"
                    >
                      Henüz kayıtlı bir veri yoktur.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <nav
            className="mt-2 float-right my-5"
            aria-label="Page navigation example"
          >
            <ul className="flex items-center -space-x-px h-10 text-sm">
              <li>
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className={`flex items-center justify-center px-4 h-10 ${
                    currentPage === 1
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 1 1 5l4 4"
                    />
                  </svg>
                </button>
              </li>
              {renderPageNumbers()}
              <li>
                <button
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                  className={`flex items-center justify-center pl-4 h-10 ${
                    currentPage === totalPages
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}

      {openPdfModal === true && (
        <OfferPdfModal
          data={data}
          pdfData={pdfData}
          onCancel={() => setOpenPdfModal(false)}
        />
      )}
      {openPdfDetailModal === true && (
        <OfferPdfDetailModal
          data={dataItem}
          onCancel={() => setOpenPdfDetailModal(false)}
        />
      )}
      <Footer />
    </>
  );
};

export default PurchaseOffers;
