import React, { useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import LoadingSpinnerModal from "../LoadingSpinnerModal";
import ShippingDetailPdf from "./ShippingDetailPdf";

const ShippingPdfDetailModal = ({ data, onCancel = () => {} }) => {
  const { user } = useAuth();
  const [logoStr, setLogoStr] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && user.country) {
      api
        .get(`/api/Customer/GetCustomerLogo?id=${user.country}`)
        .then((res) => {
          setLogoStr(res.data);
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => setLoading(false));
    }
  }, [user]);
console.log(data);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-[1000] flex justify-center items-center">
      <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
      {loading ? (
          <LoadingSpinnerModal loading={loading} />
        ) : (
        <form className="grid grid-cols-2 gap-7 items-center">
          <div className="col-span-2 text-center">
            <h1 className="font-medium text-2xl text-gray-600">
              PDF yüklendiğinde indirebilirsiniz.
            </h1>
          </div>
          <div className="col-span-2 text-center">
            <PDFDownloadLink
              document={<ShippingDetailPdf logoStr={logoStr} data={data} />}
              fileName={`${data.shippingNo || "FORM"}.pdf`}
            >
              {({ loading }) =>
                loading ? (
                  <a className="text-lg font-medium text-red-600 hover:underline">
                    Yükleniyor...
                  </a>
                ) : (
                  <a className="text-lg font-medium text-green-600 hover:underline">
                    PDF İndir
                  </a>
                )
              }
            </PDFDownloadLink>
          </div>

          <div className="col-span-2 flex justify-center gap-[20px]">
            <button
              onClick={onCancel}
              className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              title="Kapat"
            >
              Kapat
            </button>
          </div>
        </form>
        )}

      </div>
    </div>
  );
};

export default ShippingPdfDetailModal;
