import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import Swal from "sweetalert2";
import LoadingSpinnerModal from "../LoadingSpinnerModal";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Switch from "react-switch";
import InputMask from "react-input-mask";

const OpportunityInterviewEditModal = ({ onCancel = () => {}, id, interViewID}) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [interviewTypes, setInterviewTypes] = useState([]);
  const [interviewStatus, setInterviewStatus] = useState([]);
  const [isItPositive, setIsItPositive] = useState(false);
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(Number());
  const [name, setName] = useState("");
  const [contactName, setContactName] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState({});
  const [status, setStatus] = useState({});
  const [description, setDescription] = useState("");

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);

      api
        .get(`/api/Opportunity/GetOpportunityInterview?customerID=${user.country}&opportunityID=${id}&opportunityInterviewID=${interViewID}`)
        .then((res) => {
          setData(res.data);
          setName(res.data.name)
          setContactName(res.data.contactName)
          setCellphone(res.data.cellphone)
          setEmail(res.data.email)
          setType({value:res.data.type, label:res.data.typeString})
          setStatus({value:res.data.status, label:res.data.statusString})
          setIsItPositive(res.data.isItPositive)
          setDescription(res.data.description)
        })
        .catch((err) => {
          console.log(err.data);
        });
      api
        .get("/api/Common/GetParameters?parametername=InterviewStatus")
        .then((res) => {
          const newDataStatus = res.data?.map((item) => ({
            value: item.parameterID,
            label: item.parameterValue2,
          }));
          setInterviewStatus(newDataStatus);
        })
        .catch((err) => {
          console.log(err.data);
        });

      api
        .get("/api/Common/GetParameters?parametername=InterviewType")
        .then((res) => {
          const newData = res.data?.map((item) => ({
            value: item.parameterID,
            label: item.parameterValue2,
          }));
          setInterviewTypes(newData);
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => setLoading(false));
    }
  }, [user]);

  const postData = {
    customerID,
    interViewID,
    opportunityID: id,
    name,
    contactName,
    cellphone,
    email,
    type: type?.value,
    status: status?.value,
    isItPositive,
    description,
    keywords: "",
    notes: "",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı.");
      }

      await api.put(
        "/api/Opportunity/PutOpportunityInterview/PutOpportunityInterview",
        postData,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "İşlem başarılı !",
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
      onCancel();
    }
  };

  console.log(postData);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-50 flex justify-center items-center">
      <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
        {loading ? (
          <LoadingSpinnerModal loading={loading} />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-2 gap-2 items-center"
          >
            <div className="col-span-2">
              <h1 className="text-center  text-4xl font-bold leading-none tracking-tight text-blue-800 pb-10">
                Görüşme Güncelle
              </h1>
            </div>
            <div className="">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Şirket Adı :
              </label>
              <input
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                İletişim Kişisi Adı ve Soyadı :
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                onChange={(e) => setContactName(e.target.value)}
                value={contactName}
              />
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Tel No :
              </label>
              <InputMask
                mask="(999) 999-9999"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
              />
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                E-Mail :
              </label>
              <input
                type="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Tipi :
              </label>
              <Select
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                options={interviewTypes}
                value={type}
                onChange={(e) => setType(e)}
              />
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Durum :
              </label>
              <Select
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                options={interviewStatus}
                value={status}
                onChange={(e) => setStatus(e)}
              />
            </div>
            <div className="flex items-center gap-2 my-3">
              <label className="block  text-sm font-medium text-gray-900">
                Olumlu mu? :
              </label>
              <Switch
                checked={isItPositive}
                onChange={(e) => setIsItPositive(e)}
                width={50}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Açıklama :
              </label>
              <textarea
                id="description"
                type="text"
                className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="col-span-2 flex justify-center gap-[20px]">
              <button
                onClick={onCancel}
                className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              >
                Vazgeç
              </button>
              <button
                type="submit"
                className="bg-[#0C692F] hover:bg-[#0c6930d3] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              >
                Gönder
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default OpportunityInterviewEditModal;
