import React, { useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import LoadingSpinnerModal from "../LoadingSpinnerModal";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import OpportunitiesPDF from "./OpportunitiesPDF";

const OpportunitiesPdfModal = ({ data, pdfData, onCancel = () => {} }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data1, setData1] = useState([]);
  const [logoStr, setLogoStr] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && user.country) {
      api
        .get(`/api/Customer/GetCustomerLogo?id=${user.country}`)
        .then((res) => {
          setLogoStr(res.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
        
      api
        .get(
          `/api/Opportunity/GetOpportunities?customerID=${user.country}&pageNumber=1&pageSize=99999`
        )
        .then((res) => {
          const fetchedData = res.data.items;

          api
            .get("/api/Common/GetParameters?parametername=OpportunityStatus")
            .then((res) => {
              const opportunityStatus = res.data;

              // `fetchedData` içindeki her öğe için status eşlemesini yap
              const updatedData = fetchedData.map((item) => {
                const matchingStatus = opportunityStatus.find(
                  (status) => status.parameterID === item.status
                );
                return {
                  ...item,
                  status: matchingStatus || item.status, // Eşleşme yoksa eski değer
                };
              });

              setData1(updatedData); // Güncellenmiş veriyi ata
            })
            .catch((err) => {
              console.log(err.data);
              Swal.fire(
                t("errors.UnknownError", {
                  defaultValue: "Bilinmeyen bir hata",
                })
              );
            });
        })
        .catch((err) => {
          console.log(err.data);
          const errorMessageKey = err.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
          Swal.fire(translatedError);
        })
        .finally(() => setLoading(false));
    }
  }, [user]);

  console.log(logoStr);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-[1000] flex justify-center items-center">
      <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
        {loading ? (
          <LoadingSpinnerModal loading={loading} />
        ) : (
          <form className="grid grid-cols-2 gap-7 items-center">
            <div className="col-span-2 text-center">
              <h1 className="font-medium text-2xl text-gray-600">
                PDF yüklendiğinde indirebilirsiniz.
              </h1>
            </div>
            <div className="col-span-2 text-center">
              <PDFDownloadLink
                document={
                  <OpportunitiesPDF
                    logoStr={logoStr}
                    pdfData={pdfData}
                    data={data1}
                  />
                }
                fileName={`${pdfData || "FORM"}.pdf`}
              >
                {({ loading }) =>
                  loading ? (
                    <a className="text-lg font-medium text-red-600 hover:underline">
                      Yükleniyor...
                    </a>
                  ) : (
                    <a className="text-lg font-medium text-green-600 hover:underline">
                      PDF İndir
                    </a>
                  )
                }
              </PDFDownloadLink>
            </div>

            <div className="col-span-2 flex justify-center gap-[20px]">
              <button
                onClick={onCancel}
                className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
                title="Kapat"
              >
                Kapat
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default OpportunitiesPdfModal;
