import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api from "../../api/api";
import { AiOutlinePlus } from "react-icons/ai/";
import SideBarRight from "../../components/modal/SideBarRight";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";

const OpportunityAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [opportunityItems, setOpportunityItems] = useState([]);
  const [finishDate, setFinishDate] = useState(new Date());
  const [stockCards, setStockCards] = useState([]);
  const [stockCardsOpt, setStockCardsOpt] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [accountsOpt, setAccountsOpt] = useState([]);
  const [name, setName] = useState("");
  const [openSideModal, setOpenSideModal] = useState(false);
  const [token, setToken] = useState(null);
  const [description, setDescription] = useState("");
  const [rivals, setRivals] = useState("");
  const [alternatives, setAlternatives] = useState("");
  const [salesProbability, setSalesProbability] = useState(70);
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState({});

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=1045`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(
              `/api/Account/GetAccounts?customerID=${user.country}&typeID=25&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setAccounts(res.data.items);
              const newDataAccounts = res.data?.items?.map((item) => ({
                value: item.accountID,
                label: item.name,
              }));

              setAccountsOpt(newDataAccounts);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => {
              const newDataUsers = res.data?.map((item) => ({
                value: item.userID,
                label: `${item.name} ${item.surname}`,
              }));
              setUsers(newDataUsers);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&typeIDs=28&typeIDs=29&typeIDs=1663&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setStockCards(res.data.items);
              const newDataStockCards = res.data?.items?.map((item) => ({
                value: item.stockCardID,
                label: `${item.code} - ${item.name}`,
                type: item.type,
              }));

              setStockCardsOpt(newDataStockCards);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  //ADD ITEMS///

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...opportunityItems];
    list[index][name] = value;
    setOpportunityItems(list);
  };

  const handleSelectChange = (e, index) => {
    const list = [...opportunityItems];
    list[index].name = e.label;
    list[index].stockCardID = e.value;
    const stockCardsValue = stockCards.find(
      (item) => item.stockCardID === e.value
    );
    list[index].unitPrice = stockCardsValue.salesPrice;

    setOpportunityItems(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...opportunityItems];
    list.splice(index, 1);
    setOpportunityItems(list);
  };

  const handleServiceAdd = () => {
    setOpportunityItems([
      ...opportunityItems,
      {
        quantity: Number(),
        unitPrice: Number(),
        name: "",
        stockCardID: Number(),
      },
    ]);
  };

  //////////////////////////

  const find = accounts.find(
    (item) => item.accountID === selectedAccount.value
  );

  const postData = {
    customerID,
    accountID: selectedAccount.value,
    sales_UserID: selectedUser.value,
    name,
    address: find?.address,
    taxOffice: find?.taxOffice,
    taxNumber: find?.taxNumber,
    opportunityNo: "",
    expiryDate: finishDate,
    salesProbability,
    rivals,
    alternatives,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    opportunityItems,
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .post("/api/Opportunity/Post/Post", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Fırsat oluşturuldu !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/opportunities");
      })

      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire(translatedError);
      })
      .finally(() => setLoading(false));
  };

  const handleBarClick = (e) => {
    const bar = e.target.getBoundingClientRect();
    const clickX = e.clientX - bar.left; // Tıklama noktası (piksel)
    const newPercentage = Math.round((clickX / bar.width) * 100); // Yüzdeyi hesapla
    setSalesProbability(Math.max(0, Math.min(100, newPercentage))); // 0-100 arasında sınırla
  };

  const handleInputChange = (e) => {
    const value = Math.max(0, Math.min(100, Number(e.target.value))); // 0-100 arasında sınırla
    setSalesProbability(value);
  };
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Fırsatlar", href: "/opportunities" },
                { label: "Fırsat Oluştur", href: "" },
              ]}
            />

            <div className="p-12 mx-auto my-20 rounded-[3px] min-h-screen flex justify-between w-[90%] bg-gray-100">
              <form onSubmit={handleSubmit} className="w-full">
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">
                    Fırsat Oluştur
                  </h1>
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adı
                    </label>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Cari
                    </label>
                    <Select
                      classNamePrefix="custom-select"
                      maxMenuHeight={200}
                      id="name"
                      onChange={(e) => setSelectedAccount(e)}
                      value={selectedAccount}
                      options={accountsOpt}
                      placeholder="Seçiniz..."
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="taxOffice"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Vergi Dairesi
                    </label>
                    <input
                      value={find?.taxOffice}
                      readOnly
                      type="text"
                      id="taxOffice"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Vergi No
                    </label>
                    <input
                      value={find?.taxNumber}
                      readOnly
                      type="text"
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="address"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adres
                    </label>
                    <input
                      value={find?.address}
                      readOnly
                      type="text"
                      id="address"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Satış Personeli
                    </label>
                    <Select
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                      options={users}
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e)}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="rivals"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Rakipler
                    </label>
                    <input
                      type="text"
                      id="rivals"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={rivals}
                      onChange={(e) => setRivals(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="alternatives"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Alternatifler
                    </label>
                    <input
                      type="text"
                      id="alternatives"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={alternatives}
                      onChange={(e) => setAlternatives(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="salesProbability"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Satış Olaslığı
                    </label>
                    <div className="flex items-center mt-4">
                      <div
                        className="w-full h-5 mr-4 bg-gray-200 rounded dark:bg-gray-700 cursor-pointer"
                        onClick={handleBarClick} // Tıklama olayı
                      >
                        <div
                          className="h-5 bg-yellow-500 rounded"
                          style={{ width: `${salesProbability}%` }}
                        ></div>
                      </div>
                      <input
                        type="number"
                        className="w-16 p-1 text-center border rounded text-sm font-medium text-gray-500 dark:text-gray-400"
                        value={salesProbability}
                        onChange={handleInputChange}
                        min="0"
                        max="100"
                      />
                      <span className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                        %
                      </span>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama
                    </label>
                    <input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      id="description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler
                    </label>
                    <CreatableSelect
                      onChange={(e) => setKeywords(e)}
                      options={customerKey}
                      isMulti
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Geçerlilik Tarihi
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={finishDate}
                      onChange={(date) => setFinishDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                </div>

                <div className="relative overflow-x-auto mb-5">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün Adı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Miktar
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim Fiyat
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İşlem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {opportunityItems.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-6 py-4">
                            <div>
                              <Select
                                maxMenuHeight={200}
                                onChange={(e) => handleSelectChange(e, index)}
                                options={stockCardsOpt}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..." 
                                menuPortalTarget={document.body} 
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (base) => ({
                                    ...base,
                                     // Set fixed width
                                  }),
                                }}
                              />
                            </div>
                          </td>

                          <td className="px-6 py-4">
                            <input
                              value={singleService.quantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="quantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.unitPrice}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="unitPrice"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-2 px-4 rounded-lg m-5"
                              title="Sil"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <button
                      onClick={handleServiceAdd}
                      type="button"
                      className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg my-5 h-10 w-24"
                      title="Ekle"
                    >
                      Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </table>
                </div>
                <div>
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    title="Kaydet"
                  >
                    Kaydet <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
              <div>
                <button className="absolute top-36 right-0 bg-[#050A27] rounded-l-lg">
                  <AiOutlinePlus
                    size={40}
                    color="white"
                    onClick={() => setOpenSideModal(true)}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        {openSideModal === true && (
          <SideBarRight onCancel={() => setOpenSideModal(false)} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default OpportunityAdd;
