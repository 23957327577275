import React, { useState } from "react";
import Header from "../../../components/bars/Header";
import Sidebars from "../../../components/bars/Sidebar";
import "../../demands/demands.css";
import Footer from "../../../components/bars/Footer";
import Breadcrumb from "../../../components/bars/Breadcrumb";
import PurchaseInvoices from "./PurchaseInvoices";
import PurchaseEinvoices from "./PurchaseEinvoices";

const PurchaseInvoicesTabs = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTabPurchaseInvoices") || "PurchaseInvoices"
  );

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    localStorage.setItem("activeTabPurchaseInvoices", tabName);
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        <div className="flex-grow">
          <Breadcrumb
            breadcrumbData={[{ label: "Satın Alma Faturaları", href: "" }]}
          />
          <div className="m-12 min-h-screen ">
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mt-[-30px]">
              <ul className="flex flex-wrap -mb-px ">
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "PurchaseInvoices"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("PurchaseInvoices")}
                  >
                    Satın Alma Faturaları
                  </a>
                </li>
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "Epurchaseİnvoices"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("Epurchaseİnvoices")}
                  >
                    Gelen E-Faturalar
                  </a>
                </li>
    
              </ul>
            </div>
            <div>
              {activeTab === "PurchaseInvoices" && <PurchaseInvoices />}
              {activeTab === "Epurchaseİnvoices" && <PurchaseEinvoices />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PurchaseInvoicesTabs;
