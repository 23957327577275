import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAuth } from "../../context/AuthContext";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa6";
import * as XLSX from "xlsx";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { FaRegNoteSticky } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import OpportunitiesPdfModal from "./OpportunitiesPdfModal";

const Opportunities = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userName, setUsername] = useState("");
  const [customerID, setCustomerID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [opportunityStatus, setOpportunityStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını depolamak için
  const [pageSize, setPageSize] = useState(20);
  const [openModal, setOpenModal] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [sortOrder, setSortOrder] = useState({
    name: "desc",
    contactName: "desc",
    telephone: "desc",
    email: "desc",
    cityName: "desc",
  });
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setLoading(true);

    if (user && user.country) {
      setCustomerID(user.country);
      setUsername(user.name);

      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=1045`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get("/api/Common/GetParameters?parametername=OpportunityStatus")
            .then((res) => {
              setOpportunityStatus(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(
              `/api/Opportunity/GetOpportunities?customerID=${user.country}&pageNumber=${currentPage}&pageSize=${pageSize}`
            )
            .then((res) => {
              setData(res.data.items);
              setTotalPages(res.data.totalPages);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, currentPage, pageSize]);

  ///////////////////////// PAGING
  const nextPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)); // Son sayfayı aşmamak için kontrol
  };

  const prevPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5 && currentPage > 3) {
      startPage = currentPage - 2;
      endPage = Math.min(currentPage + 2, totalPages);
    } else {
      endPage = Math.min(totalPages, 5);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`flex items-center justify-center px-4 h-10 leading-tight ${
              currentPage === i
                ? "text-blue-600 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }`}
            onClick={() => goToPage(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
  };

  const handleSort = (columnName) => {
    const sortOrderCopy = { ...sortOrder };
    sortOrderCopy[columnName] =
      sortOrder[columnName] === "asc" ? "desc" : "asc";
    setSortOrder(sortOrderCopy);

    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (
          columnName === "name" ||
          columnName === "contactName" ||
          columnName === "telephone" ||
          columnName === "email" ||
          columnName === "cityName"
        ) {
          if (sortOrderCopy[columnName] === "asc") {
            return a[columnName]?.localeCompare(b[columnName]);
          } else {
            return b[columnName]?.localeCompare(a[columnName]);
          }
        } else {
          // Sayısal sütunlar için sıralama işlevini çağır
          return numericSort(
            a[columnName],
            b[columnName],
            sortOrderCopy[columnName]
          );
        }
      });
      return sortedData;
    });
  };

  const numericSort = (a, b, sortOrder) => {
    if (sortOrder === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };
  /////////////////////////////////////////////

  const handleDelete = async (opportunityID, customerID) => {
    try {
      // await
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Opportunity/DeleteOpportunity/${opportunityID}?customerID=${customerID}`
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.opportunityID !== opportunityID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Fırsat silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Fırsat silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };
  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  const handleAddNote = async (opportunityID) => {
    setLoading(true);

    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    if (!token) {
      Swal.fire("Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }

    const noteObject = {
      opportunityID: opportunityID, // opportunityID'yi burada alıyoruz
      note: newNote,
      noteDate: new Date(), // ISO format tarih
      userName: userName,
    };

    try {
      const res = await api.post(
        `/api/Opportunity/PostOpportunityNotes/PostOpportunityNotes`,
        noteObject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Not oluşturuldu",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  const openNoteModal = (opportunityID) => {
    Swal.fire({
      title: "Not ekle",
      input: "textarea",
      inputPlaceholder: "Notunuzu buraya yazın...",
      showCancelButton: true,
      confirmButtonText: "Ekle",
      cancelButtonText: "İptal",
      inputValidator: (value) => {
        if (!value) {
          return "Lütfen bir not yazın!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        setNewNote(result.value);
        handleAddNote(opportunityID); // opportunityID'yi buraya gönderiyoruz
      }
    });
  };

  // ITEMS TO EXCEL
  const handleExportData = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token");
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPdfData(response.data);

      const worksheet = XLSX.utils.json_to_sheet(data);

      // Örneğin, A, B, C sütunları için genişlik ayarı
      worksheet["!cols"] = [
        { wch: 15 }, // A sütunu genişliği
        { wch: 15 }, // B sütunu genişliği
        { wch: 60 }, // C sütunu genişliği
        { wch: 10 }, // D sütunu genişliği
        { wch: 50 }, // E sütunu genişliği
        { wch: 50 }, // F sütunu genişliği
        { wch: 20 }, // G sütunu genişliği
        { wch: 60 }, // H sütunu genişliği
        { wch: 20 }, // I sütunu genişliği
        { wch: 20 }, // J sütunu genişliği
        { wch: 60 }, // K sütunu genişliği
        { wch: 40 }, // L sütunu genişliği
        { wch: 40 }, // M sütunu genişliği
        { wch: 10 }, // N sütunu genişliği
        { wch: 10 }, // O sütunu genişliği
        { wch: 10 }, // P sütunu genişliği
        { wch: 7 }, // Q sütunu genişliği
        { wch: 10 }, // R sütunu genişliği
        { wch: 10 }, // S sütunu genişliği
        { wch: 20 }, // T sütunu genişliği
        { wch: 10 }, // U sütunu genişliği
        { wch: 10 }, // V sütunu genişliği
        { wch: 10 }, // W sütunu genişliği
        { wch: 10 }, // X sütunu genişliği
        { wch: 5 }, // Y sütunu genişliği
        { wch: 10 }, // Z sütunu genişliği
        { wch: 8 }, // AA sütunu genişliği
        { wch: 10 }, // AB sütunu genişliği
        { wch: 10 }, // AC sütunu genişliği
        { wch: 100 }, // AD sütunu genişliği
        { wch: 50 }, // AE sütunu genişliği
        { wch: 50 }, // AF sütunu genişliği
        { wch: 10 }, // AG sütunu genişliği
        // ve böyle devam eder...
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const fileName = `${response.data}.xlsx`; // Dosya adını ve uzantısını belirtiyoruz
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDocumentNumber = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token"); // Token'ı localStorage'dan alabilirsiniz
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header'ını ekliyoruz
          },
        }
      );

      // Mevcut veri ile API'den gelen veriyi birleştiriyoruz

      setPdfData(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfModal(true); // Modal'ı açıyoruz
    }
  };
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb breadcrumbData={[{ label: "Fırsatlar", href: "" }]} />
            <div className="m-12 min-h-screen">
              <div className="add-button">
                {permissions.insertPermission && (
                  <Link to={"/opportunityAdd"}>
                    <button
                      type="button"
                      className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-72"
                      title="Fırsat oluştur"
                    >
                      Fırsat Oluştur
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </Link>
                )}

                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">Fırsatlar</h1>
                </div>
                <div className="flex justify-between items-center pt-2">
                  <div className="flex gap-2">
                    <div>
                      <input
                        className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg pb-3"
                        type="text"
                        placeholder="Fırsat ara.."
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                    </div>
                  </div>
                  <div className="flex gap-5 mr-4">
                    <a
                      className="font-medium text-[#050A27] hover:underline cursor-pointer"
                      title="PDF çıktı al"
                      onClick={fetchDocumentNumber}
                    >
                      <FaRegFilePdf
                        className="hover:scale-110 transition-transform duration-200"
                        size={30}
                      />
                    </a>
                    <button>
                      <a
                      onClick={handleExportData}
                        title="Excel çıktı al"
                        className="font-medium text-[#050A27] hover:underline"
                      >
                        <RiFileExcel2Line
                          className="hover:scale-110 transition-transform duration-200"
                          size={35}
                        />
                      </a>
                    </button>
                  </div>
                </div>
              </div>

              <div className="relative overflow-x-auto mb-5">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-[#050A27]">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 cursor-pointer"
                        onClick={() => handleSort("name")}
                      >
                        Fırsat No {sortOrder.name === "asc" ? "▲" : "▼"}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 cursor-pointer"
                        onClick={() => handleSort("contactName")}
                      >
                        Adı {sortOrder.contactName === "asc" ? "▲" : "▼"}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 cursor-pointer"
                        onClick={() => handleSort("telephone")}
                      >
                        Geçerlilik Tarihi{" "}
                        {sortOrder.telephone === "asc" ? "▲" : "▼"}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 cursor-pointer"
                        onClick={() => handleSort("email")}
                      >
                        Durum {sortOrder.email === "asc" ? "▲" : "▼"}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İslemler
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data
                        ?.filter(
                          (item) =>
                            item.name
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.opportunityNo
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase())
                        )
                        .map((item, index) => {
                          const statusValue = opportunityStatus.find(
                            (status) => status.parameterID === item.status
                          )?.parameterValue2;
                          return (
                            <tr
                              key={index}
                              className="bg-white border-b hover:bg-gray-50"
                            >
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                              >
                                {item.opportunityNo}
                              </th>
                              <td className="px-6 py-4">{item.name}</td>
                              <td className="px-6 py-4">
                                {formatDate(item.expiryDate)}
                              </td>
                              <td className="px-6 py-4">
                                {statusValue || "Bilinmiyor"}
                              </td>

                              <td className="flex items-center px-6 py-4 space-x-3">
                                {permissions.updatePermission && (
                                  <button
                                    onClick={() =>
                                      openNoteModal(item.opportunityID)
                                    }
                                  >
                                    <a
                                      title="Not Ekle"
                                      className="font-medium text-[#050A27] hover:underline"
                                    >
                                      <FaRegNoteSticky
                                        className="hover:scale-110 transition-transform duration-200"
                                        size={24}
                                      />
                                    </a>
                                  </button>
                                )}
                                {permissions.updatePermission && (
                                  <Link
                                    to={`/opportunityEdit/${item.opportunityID}`}
                                  >
                                    {" "}
                                    <a
                                      title="Fırsat düzenle"
                                      className="font-medium text-[#050A27] hover:underline"
                                    >
                                      <TbEdit
                                        className="hover:scale-110 transition-transform duration-200"
                                        size={24}
                                      />
                                    </a>
                                  </Link>
                                )}
                                {permissions.deletePermission && (
                                  <button
                                    onClick={() => {
                                      handleDelete(
                                        item.opportunityID,
                                        item.customerID
                                      );
                                    }}
                                  >
                                    <a
                                      title="Fırsat sil"
                                      className="font-medium text-[#050A27] hover:underline"
                                    >
                                      <RiDeleteBin6Line
                                        className="hover:scale-110 transition-transform duration-200"
                                        size={24}
                                      />
                                    </a>
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr>
                        <td
                          colSpan={6}
                          className="px-6 py-4 text-center text-2xl font-medium"
                        >
                          Henüz kayıtlı bir veri yoktur.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <nav
                className="mt-2 float-right"
                aria-label="Page navigation example"
              >
                <ul className="flex items-center -space-x-px h-10 text-sm">
                  <li>
                    <button
                      onClick={prevPage}
                      disabled={currentPage === 1}
                      className={`flex items-center justify-center px-4 h-10 ${
                        currentPage === 1
                          ? "text-gray-300 cursor-not-allowed"
                          : "text-gray-500"
                      }`}
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        className="w-2.5 h-2.5 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 1 1 5l4 4"
                        />
                      </svg>
                    </button>
                  </li>
                  {renderPageNumbers()}
                  <li>
                    <button
                      onClick={nextPage}
                      disabled={currentPage === totalPages}
                      className={`flex items-center justify-center pl-4 h-10 ${
                        currentPage === totalPages
                          ? "text-gray-300 cursor-not-allowed"
                          : "text-gray-500"
                      }`}
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="w-2.5 h-2.5 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
      {openPdfModal === true && (
        <OpportunitiesPdfModal
          data={data}
          pdfData={pdfData}
          onCancel={() => setOpenPdfModal(false)}
        />
      )}
      <Footer />
    </>
  );
};

export default Opportunities;
