import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import Swal from "sweetalert2";
import LoadingSpinnerModal from "../LoadingSpinnerModal";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Switch from "react-switch";

const EsalesInvoiceModal = ({
  onCancel = () => {},
  selectedOpenModalId,
  selectedOpenModalInvoiceId,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [exemptionCodes, setExemptionCodes] = useState([]);
  const [taxExemptionCode, setTaxExemptionCode] = useState({
    value: "",
    label: "Seçiniz...",
  });
  const [eInvoiceScenarioType, setEInvoiceScenarioType] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [eInvoiceScenarioTypes, setEInvoiceScenarioTypes] = useState([
    { value: 1, label: "Temel" },
    { value: 2, label: "Ticari" },
  ]);
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(Number());
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().slice(0, 16)
  );

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(`/api/EInvoice/GetTaxExemptionCodes`)
        .then((res) => {
          const newData = res.data?.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setExemptionCodes(newData);
        })
        .catch((err) => console.log(err.data));

      api
        .get(
          `/api/Account/Get?id=${selectedOpenModalId}&customerID=${user.country}`
        )
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const postData = {
    customerID,
    invoiceID: selectedOpenModalInvoiceId,
    eInvoiceScenarioType: eInvoiceScenarioType?.value,
    taxExemptionCode: taxExemptionCode?.value,
    description: "",
    extension: "",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı.");
      }

      await api.post("/api/EInvoice/Post/Post", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "İşlem başarılı !",
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
      onCancel();
    }
  };

  console.log(postData);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-50 flex justify-center items-center">
      <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
        {loading ? (
          <LoadingSpinnerModal loading={loading} />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-2 gap-2 items-center"
          >
            <div className="col-span-2">
              <h1 className="text-center  text-4xl font-bold leading-none tracking-tight text-blue-800 pb-10">
                E-Fatura Dönüştür
              </h1>
            </div>
            <div className="col-span-2">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Cari :
              </label>
              <input
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.name}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Vergi Dairesi :
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.taxOffice}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Vergi No :
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={data?.taxNumber}
                disabled
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Tarih ve Saat:
              </label>
              <input
                type="datetime-local"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={currentDate}
                disabled
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Fatura Tipi :
              </label>
              <Select
                options={eInvoiceScenarioTypes}
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                value={eInvoiceScenarioType}
                onChange={(e) => setEInvoiceScenarioType(e)}
                required
              />
            </div>
            <div className="flex items-center gap-2 my-3">
              <label className="block  text-sm font-medium text-gray-900">
                KDV Muafiyetli Ürün Var mı? :
              </label>
              <Switch
                checked={isChecked}
                onChange={(e) => setIsChecked(e)}
                width={50}
              />
            </div>
            {isChecked && (
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  KDV Muafiyet Sebebi :
                </label>
                <Select
                  options={exemptionCodes}
                  maxMenuHeight={200}
                  placeholder={"Seçiniz..."}
                  classNamePrefix="custom-select"
                  value={taxExemptionCode}
                  onChange={(e) => setTaxExemptionCode(e)}
                />
              </div>
            )}

            <div className="col-span-2 flex justify-center gap-[20px]">
              <button
                onClick={onCancel}
                className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              >
                Vazgeç
              </button>
              <button
                type="submit"
                className="bg-[#0C692F] hover:bg-[#0c6930d3] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              >
                Gönder
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EsalesInvoiceModal;
