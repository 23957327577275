import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import "./Timeline.css";
import Timeline, {
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import api from "../../api/api";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";

const options = [
  { value: "this_month", label: "Bu Ay" },
  { value: "last_month", label: "Geçen Ay" },
  { value: "this_year", label: "Bu Yıl" },
  { value: "custom", label: "Tarih Seçin" },
];

const PersonelShifts = () => {
  const { user } = useAuth();
  const [permissions, setPermissions] = useState(null);
  const [selectedOperationGroup, setSelectedOperationGroup] = useState({
    value: null,
    label: "Operasyon Grubu Seçiniz...",
  });
  const [machineGroups, setMachineGroups] = useState([]);
  const [selectedMachineGroup, setSelectedMachineGroup] = useState({
    value: null,
    label: "Makine Grubu Seçiniz...",
  });
  const [operationGroups, setOperationGroups] = useState([]);
  const [machines, setMachines] = useState([]);
  const [operations, setOperations] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  });
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();
    return new Date(today.getFullYear(), today.getMonth(), lastDayOfMonth);
  });
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState({
    value: "this_month",
    label: "Bu Ay",
  });

  useEffect(() => {
    setLoading(true);
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=26`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/Operation/GetoperationGroups?customerID=${user.country}`)
            .then((res) => {
              const dataOperationGroups = res.data;
              const newDataOperationGroups = dataOperationGroups?.map(
                (item) => ({
                  value: item.operationGroupID,
                  label: item.name,
                })
              );
              setOperationGroups(newDataOperationGroups);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Machine/GetMachineGroups?customerID=${user.country}`)
            .then((res) => {
              const dataMachineGroups = res.data;
              const newDataMachineGroups = dataMachineGroups?.map((item) => ({
                value: item.machineGroupID,
                label: item.name,
              }));
              setMachineGroups(newDataMachineGroups);
            })
            .catch((err) => console.error(err.data));

          api
            .get(
              `/api/Machine/GetMachines?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const newFormattedMachineShifts = res.data.items.map(
                (machine) => ({
                  id: `machine_${machine.machineID}`,
                  title: (
                    <>
                      <i className="fa-solid fa-gears text-red-600"></i>{" "}
                      {machine.name}
                    </>
                  ),
                  group: `machine_${machine.machineID}`, // Makine grupları için farklı bir ID oluştur
                })
              );
              setMachines(newFormattedMachineShifts);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Operation/Getoperations?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const newFormattedOperationShifts = res.data.items.map(
                (operation) => ({
                  id: `operation_${operation.operationID}`,
                  title: (
                    <>
                      <i className="fa-solid fa-person-digging text-green-600"></i>{" "}
                      {operation.name}
                    </>
                  ),
                  group: `operation_${operation.operationID}`, // Operasyon grupları için farklı bir ID oluştur
                })
              );
              setOperations(newFormattedOperationShifts);
            })
            .catch((err) => console.log(err.data));

          const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
          const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

          api
            .get(
              `/api/Shift/GetShiftPlaning?customerID=${user.country}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
            )
            .then((res) => {
              const newFormattedShifts = res.data.map((item) => {
                let groupID;

                if (item.machineID) {
                  groupID = `machine_${item.machineID}`;
                } else if (item.operationID) {
                  groupID = `operation_${item.operationID}`;
                }

                return {
                  id: item.shiftID,
                  group: groupID,
                  title: item.workOrderNames[0], // Dize olarak işlenmemiş
                  start_time: moment(item.startDate).toDate(),
                  end_time: moment(item.endDate).toDate(),
                  canMove: false,
                  canResize: false,
                  canChangeGroup: false,
                };
              });
              setShifts(newFormattedShifts);
            })
            .catch((err) => console.log(err.message))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, startDate, endDate]);

  const selectOperationGroup = async (e) => {
    setLoading(true);
    setSelectedOperationGroup(e);
    setSelectedMachineGroup({
      value: null,
      label: "Makine Grubu Seçiniz...",
    });
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    await api
      .get(
        `/api/Shift/GetShiftPlaning?customerID=${customerID}&operationGroupID=${e.value}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      )
      .then((res) => {
        const newOperationFormattedShifts = res.data.map((item) => {
          return {
            id: item.shiftID,
            group: `operation_${item.operationID}`,
            title: item.workOrderNames[0], // Dize olarak işlenmemiş
            start_time: moment(item.startDate).toDate(),
            end_time: moment(item.endDate).toDate(),
            canMove: false,
            canResize: false,
            canChangeGroup: false,
          };
        });
        setShifts(newOperationFormattedShifts);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  };

  const selectMachineGroup = async (e) => {
    setLoading(true);
    setSelectedMachineGroup(e);
    setSelectedOperationGroup({
      value: null,
      label: "Operasyon Grubu Seçiniz...",
    });
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    await api
      .get(
        `/api/Shift/GetShiftPlaning?customerID=${customerID}&machineGroupID=${e.value}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      )
      .then((res) => {
        const newMachineFormattedShifts = res.data.map((item) => {
          return {
            id: item.shiftID,
            group: `machine_${item.machineID}`,
            title: item.workOrderNames[0], // Dize olarak işlenmemiş
            start_time: moment(item.startDate).toDate(),
            end_time: moment(item.endDate).toDate(),
            canMove: false,
            canResize: false,
            canChangeGroup: false,
          };
        });
        setShifts(newMachineFormattedShifts);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  };

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleChange = (option) => {
    setSelectedOption(option);
    setSelectedOperationGroup({
      value: null,
      label: "Operasyon Grubu Seçiniz...",
    });
    setSelectedMachineGroup({
      value: null,
      label: "Makine Grubu Seçiniz...",
    });

    // Seçilen seçenek "Bu Ay" ise
    if (option.value === "this_month") {
      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      setStartDate(startOfMonth);
      setEndDate(endOfMonth);
    }

    // Seçilen seçenek "Geçen Ay" ise
    if (option.value === "last_month") {
      const today = new Date();
      const startOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

      setStartDate(startOfLastMonth);
      setEndDate(endOfLastMonth);
    }

    // Seçilen seçenek "Bu Yıl" ise
    if (option.value === "this_year") {
      const today = new Date();
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      const endOfYear = new Date(today.getFullYear(), 11, 31);

      setStartDate(startOfYear);
      setEndDate(endOfYear);
    }
  };

  const renderDatePicker = () => {
    if (selectedOption && selectedOption.value === "custom") {
      return (
        <DatePicker
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-2"
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          locale={tr}
          dateFormat="d MMMM, yyyy "
          selectsRange
        />
      );
    }
    return null;
  };

  // console.log("start",startDate);
  // console.log("end",endDate);
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[{ label: "Vardiya Takvimi", href: "" }]}
            />
            <div className="mt-12 mx-auto min-h-screen w-[75vw]">
              <div className="add-button">
                {permissions.insertPermission && (
                  <>
                    <Link to={"/personelShiftAdd"}>
                      <button
                        type="button"
                        className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-72"
                      >
                        Vardiya Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                    </Link>
                    <Link to={"/generalShiftAdd"}>
                      <button
                        type="button"
                        className="butonCl text-white bg-[#050A27] hover:bg-[#050a27cc] focus:outline-none focus:ring-4 focus:ring-[#050a27c2] font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-72"
                      >
                        Genel Vardiya Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                    </Link>
                    <Link to={"/personsOvertimeAdd"}>
                      <button
                        type="button"
                        className="butonCl text-white bg-[#B10F0F] hover:bg-[#b10f0fc5] focus:outline-none focus:ring-4 focus:ring-[#b10f0fc5] font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-72"
                      >
                        Mesai Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                    </Link>
                  </>
                )}

                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">
                    Vardiya Takvimi
                  </h1>
                </div>
                <div className="flex justify-end gap-6 pt-2">
                  <div className="w-full flex flex-col">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Tarih :
                    </label>
                    <Select
                      value={selectedOption}
                      onChange={handleChange}
                      options={options}
                      menuPortalTarget={document.body} // Menüyü body içinde render eder
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }), // z-index ayarını burada yapın
                      }}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                    {renderDatePicker()}
                  </div>
                  <div className="w-full flex flex-col">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Makine Grupları :
                    </label>
                    <Select
                      maxMenuHeight={200}
                      options={machineGroups}
                      value={selectedMachineGroup}
                      onChange={(e) => selectMachineGroup(e)}
                      menuPortalTarget={document.body} // Menüyü body içinde render eder
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }), // z-index ayarını burada yapın
                      }}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Operasyon Grupları :
                    </label>
                    <Select
                      maxMenuHeight={200}
                      options={operationGroups}
                      value={selectedOperationGroup}
                      onChange={(e) => selectOperationGroup(e)}
                      menuPortalTarget={document.body} // Menüyü body içinde render eder
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }), // z-index ayarını burada yapın
                      }}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>
                </div>
              </div>

              <div className="w-full overflow-y-scroll max-h-[75vh]">
                <Timeline
                  groups={[...machines, ...operations]}
                  items={shifts}
                  defaultTimeStart={moment().startOf("month")}
                  defaultTimeEnd={moment().endOf("month")}
                  lineHeight={50}
                  itemHeightRatio={0.9}
                  sidebarWidth={250}
                  onItemClick={(e) => navigate(`/shiftDetails/${e}`)}
                >
                  <TimelineMarkers>
                    <TodayMarker />
                  </TimelineMarkers>
                </Timeline>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PersonelShifts;
