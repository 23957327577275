import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api from "../../api/api";
import { AiOutlinePlus } from "react-icons/ai/";
import SideBarRight from "../../components/modal/SideBarRight";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import InputMask from "react-input-mask";
import Switch from "react-switch";

const FinancialRegisterEdit = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [openingDate, setOpeningDate] = useState(new Date());
  const [name, setName] = useState("");
  const [openSideModal, setOpenSideModal] = useState(false);
  const [token, setToken] = useState(null);
  const [currency, setCurrency] = useState([]);
  const [registerTypes, setRegisterTypes] = useState([]);
  const [registerStatus, setRegisterStatus] = useState([]);
  const [dafault, setDafault] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [type, setType] = useState({});
  const [status, setStatus] = useState({});
  const [totalBalance, setTotalBalance] = useState(Number());
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [iban, setIban] = useState("");

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=2045`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get("/api/Common/GetCurrencies")
            .then((res) => {
              const newDataCurrency = res.data?.map((item) => ({
                value: item.currencyID,
                label: item.name,
              }));

              setCurrency(newDataCurrency);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetParameters?parametername=RegisterType")
            .then((res) => {
              const newDataTypes = res.data?.map((item) => ({
                value: item.parameterID,
                label: item.parameterValue2,
              }));
              setRegisterTypes(newDataTypes);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=RegisterStatus")
            .then((res) => {
              const newDataStatus = res.data?.map((item) => ({
                value: item.parameterID,
                label: item.parameterValue2,
              }));
              setRegisterStatus(newDataStatus);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Register/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setName(res.data.name);
              setSelectedCurrency({
                value: res.data.currencyID,
                label: res.data.currencyName,
              });
              setBankName(res.data.bankName);
              setBankBranch(res.data.bankBranch);
              setBankAccountNumber(res.data.bankAccountNumber);
              setIban(res.data.iban);
              setType({ value: res.data.type, label: res.data.typeString });
              setStatus({
                value: res.data.status,
                label: res.data.statusString,
              });
              setTotalBalance(res.data.totalBalance);
              setOpeningDate(
                res.data.openingDate ? new Date(res.data.openingDate) : null
              );
              setDafault(res.data.dafault);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  //////////////////////////

  const postData = {
    customerID,
    registerID: params.id,
    currencyID: selectedCurrency?.value,
    dafault,
    name,
    openingDate,
    totalBalance,
    paymentType: null,
    bankName,
    bankBranch,
    bankAccountNumber,
    IBAN: iban,
    type: type?.value,
    status: status?.value,
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Token bulunamadı! Lütfen giriş yapmayı deneyin.",
      });
      setLoading(false);
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .put("/api/Register/Put/Put", postData, config)
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Finansal kasa güncellendi!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/financialregisters");
      })
      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire(translatedError);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Finansal Kasalar", href: "/financialregisters" },
                { label: "Finansal Kasa Güncelle", href: "" },
              ]}
            />

            <div className="p-12 mx-auto my-20 rounded-[3px] min-h-screen flex justify-between w-[90%] bg-gray-100">
              <form onSubmit={handleSubmit} className="w-full">
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">
                    Finansal Kasa Güncelle
                  </h1>
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Finansal Kasa Adı
                    </label>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Para Birimi
                    </label>
                    <Select
                      classNamePrefix="custom-select"
                      maxMenuHeight={200}
                      options={currency}
                      placeholder="Seçiniz..."
                      value={selectedCurrency}
                      onChange={(e) => setSelectedCurrency(e)}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="bankName"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Banka Adı
                    </label>
                    <input
                      type="text"
                      id="BankName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="bankBranch"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Banka Şubesi
                    </label>
                    <input
                      type="text"
                      id="bankBranch"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={bankBranch}
                      onChange={(e) => setBankBranch(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="bankAccountNumber"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Banka Hesap Numarası
                    </label>
                    <input
                      type="number"
                      id="bankAccountNumber"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={bankAccountNumber}
                      onChange={(e) => setBankAccountNumber(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="IBAN"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      IBAN
                    </label>
                    <InputMask
                      id="IBAN"
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="TR00 0000 0000 0000 0000 0000 00"
                      mask="aa99 9999 9999 9999 9999 9999 99"
                      required
                      value={iban}
                      onChange={(e) => setIban(e.target.value)}
                    />
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Tip
                    </label>
                    <Select
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                      options={registerTypes}
                      value={type}
                      onChange={(e) => setType(e)}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Durum
                    </label>
                    <Select
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                      options={registerStatus}
                      value={status}
                      onChange={(e) => setStatus(e)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="totalBalance"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Toplam Bakiye
                    </label>
                    <input
                      type="number"
                      id="totalBalance"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={totalBalance}
                      onChange={(e) => setTotalBalance(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açılış Tarihi
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={openingDate}
                      onChange={(date) => setOpeningDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div className="flex items-center pt-5 gap-2">
                    <label
                      className="block text-sm font-medium text-gray-900"
                      htmlFor=""
                    >
                      Varsayılan Tahsilat Kasa :
                    </label>
                    <Switch checked={dafault} onChange={(e) => setDafault(e)} />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    title="Kaydet"
                  >
                    Kaydet <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
              <div>
                <button className="absolute top-36 right-0 bg-[#050A27] rounded-l-lg">
                  <AiOutlinePlus
                    size={40}
                    color="white"
                    onClick={() => setOpenSideModal(true)}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        {openSideModal === true && (
          <SideBarRight onCancel={() => setOpenSideModal(false)} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default FinancialRegisterEdit;
